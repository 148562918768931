import { configureStore } from "@reduxjs/toolkit";
import bookingDataSlice from "../Redux/BookingData/bookingDataSlice";
import rentalSummaryDataSlice from "./RentalSummaryData/rentalSummaryDataSlice";
import authSlice from "./Auth/authSlice";
import persistConfig from "./persistConfig";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  bookingValues: bookingDataSlice,
  rentalSummary: rentalSummaryDataSlice,
  authSlice: authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: {
//     bookingValues: bookingDataSlice,
//     rentalSummary: rentalSummaryDataSlice,
//     authSlice: authSlice,
//   },
// });

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
