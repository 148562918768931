import { useLocation, useNavigate } from "react-router-dom";
import { vector } from "../../assets/images";

const PaymentFailed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");

  return (
    <div className="d-flex flex flex-col align-items-center justify-content-center  vh-100  justify-center bg-gray-900 text-center px-4 dashboard-poppins-font-style dashboardright">


      <div className=" bg-black text-white max-w-md col-md-8 col-lg-6 w-full p-3 rounded-3">
        <div className="mb-6">
          <img
            src={vector}
            width="120"
            height="120"
            alt="Error"
            className="animate-shake"
          />
        </div>
        <div className="p-8">
          <h1 className="text-3xl font-bold text-red-500 dashboard-poppins-font-style ">Payment Failed ❌</h1>

          <p className="text-yellow-400 font-semibold text-lg mt-4 dashboard-poppins-font-style ">
            Order ID: #{OrderId}
          </p>
          <p className="mt-2 text-gray-300 dashboard-poppins-font-style ">
            Your payment was not processed. Please try again.
          </p>

          <div className="mt-6 flex flex-col gap-3">
            <button
              onClick={() => navigate("/customer/processpayment")}
              className="btn-lg all_button_style p-2 px-3"
            >
              Retry Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
