// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-calendar div {
  scrollbar-width: none !important;
}
.booking-calendar {
  overflow-x: auto;
  scrollbar-width: none;
  .MuiPaper-rounded {
    background-color: var(--bg-colour-theme-1) !important;
  }
  .rs__multi_day {
    .MuiPaper-rounded {
      background-color: var(--bg-colour-theme-43) !important;
    }
  }
  .rs__cell {
    background-color: transparent !important;
  }
  .rs__header {
    background-color: var(--bg-color-theme-18) !important;
    color: var(--font-color-primary) !important;
  }
  .rs__hover__op {
    color: var(--font-color-primary) !important;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: var(--font-color-primary) !important;
  }
  .css-fvtpii-MuiTypography-root {
    display: none !important;
  }
}
.MuiList-root {
  background-color: var(--bg-colour-theme-1);
}
.MuiPopover-paper {
  background-color: var(--bg-color-theme-18) !important;
  .MuiBox-root {
    background-color: var(--bg-color-theme-18) !important;
    .rs__popper_actions {
      text-align: right !important;
      justify-content: end !important;
    }
    .MuiIconButton-root {
      background-color: var(--bg-colour-theme-10) !important;
    }
  }
  .MuiTypography-noWrap {
    color: var(--font-color-primary) !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerDashBoard/BookingCalendar/styles.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;AACA;EACE,gBAAgB;EAChB,qBAAqB;EACrB;IACE,qDAAqD;EACvD;EACA;IACE;MACE,sDAAsD;IACxD;EACF;EACA;IACE,wCAAwC;EAC1C;EACA;IACE,qDAAqD;IACrD,2CAA2C;EAC7C;EACA;IACE,2CAA2C;EAC7C;EACA;IACE,2CAA2C;EAC7C;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,qDAAqD;EACrD;IACE,qDAAqD;IACrD;MACE,4BAA4B;MAC5B,+BAA+B;IACjC;IACA;MACE,sDAAsD;IACxD;EACF;EACA;IACE,2CAA2C;EAC7C;AACF","sourcesContent":[".booking-calendar div {\n  scrollbar-width: none !important;\n}\n.booking-calendar {\n  overflow-x: auto;\n  scrollbar-width: none;\n  .MuiPaper-rounded {\n    background-color: var(--bg-colour-theme-1) !important;\n  }\n  .rs__multi_day {\n    .MuiPaper-rounded {\n      background-color: var(--bg-colour-theme-43) !important;\n    }\n  }\n  .rs__cell {\n    background-color: transparent !important;\n  }\n  .rs__header {\n    background-color: var(--bg-color-theme-18) !important;\n    color: var(--font-color-primary) !important;\n  }\n  .rs__hover__op {\n    color: var(--font-color-primary) !important;\n  }\n  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {\n    color: var(--font-color-primary) !important;\n  }\n  .css-fvtpii-MuiTypography-root {\n    display: none !important;\n  }\n}\n.MuiList-root {\n  background-color: var(--bg-colour-theme-1);\n}\n.MuiPopover-paper {\n  background-color: var(--bg-color-theme-18) !important;\n  .MuiBox-root {\n    background-color: var(--bg-color-theme-18) !important;\n    .rs__popper_actions {\n      text-align: right !important;\n      justify-content: end !important;\n    }\n    .MuiIconButton-root {\n      background-color: var(--bg-colour-theme-10) !important;\n    }\n  }\n  .MuiTypography-noWrap {\n    color: var(--font-color-primary) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
