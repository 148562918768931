import CustomerInfo from "./LeftComponent";
import CustomCheckBox from "./customCheckbox";
import CustomerSignUp from "./CustomerSignUp";
import RegistrationSuccessComponent from "./RegistrationSuccessComponent";
import Progressbar from "./Progressbar";
import CustomInput from "./CustomInput";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import RegistrationModal from "./RegistrationModal";
import { TimePicker } from "./TimePicker";
import CustomRadio from "./customRadio";
import MerchantDashboardSidebar from "./MerchantDashboard/MerchantDashboardSidebar";
import MerchantDashboardnavbar from "./MerchantDashboard/MerchantDashboardNavbar";
import CreateProduct from "./MerchantDashboard/CreateProduct";
import ManageCarList from "./MerchantDashboard/ManageCarList";
import MainPage from "./MerchantDashboard/MainPage";
import MerchantDashboardFullComponents from "./MerchantDashboard/MerchantDashboardFullComponents";
import LocationStatus from "./LocationStatus";
import CreateProductCarInfo from "./MerchantDashboard/CreateProductCarInfo";

import CreateProductInsurance from "./MerchantDashboard/CreateproductInsurance";
import MapBox from "./MapBox";
import MerchantManageMyCars from "./MerchantDashboard/MerchantManageMyCars";
import CustomerDashBoardMyAccount from "./CustomerDashBoard/CustomerDashBoardMyAccount";
import CustomerDashBoardNavbar from "./CustomerDashBoard/CustomerDashBoardNavbar";
import CustomerDashBoardSidebar from "./CustomerDashBoard/CustomerDashBoardSidebar";
import ProductDetails from "./MerchantDashboard/ProductDetails";
import RentalSummary from "./CustomerDashBoard/RentalSummary";
import FinalReport from "./CustomerDashBoard/FinalReport";
import VehicleInspection from "./CustomerDashBoard/VehicleInspection";
import InsuranceHolderVehicleInspection from "./CustomerDashBoard/InsuranceHolderVehicleInspection";
import NonInsuranceHolder from "./CustomerDashBoard/NonInsuranceHolder";
import BookingProcess from "./CustomerDashBoard/BookingProcess";
import MerchantOnboardLeftSide from "./MerchantOnboardLeftSide";
import NonInsuranceHolderSecondPage from "./NonInsuranceHolderSecondPage";
import RentalSummarySecondPage from "./CustomerDashBoard/RentalSummarySecondPage";
import RentalSummaryCarousel from "./CustomerDashBoard/RentalSummaryCarousel";
import ManageMyProfile from "./CustomerDashBoard/ManageMyProfile";
import RentDeals from "./CustomerDashBoard/RentDeals";
import Customersettings from "./CustomerDashBoard/CustomerSettings";
import Merchantmanagemyprofile from "./MerchantDashboard/MerchantManageMyProfile";
import Merchantsettings from "./MerchantDashboard/MerchantSettings";
import CustomerOnboardLeftSide from "./CustomerOnboardLeftSide";
import CoustomerInvoiceList from "./MerchantDashboard/MerchantDashboardCoustomerInvoiceList";
import CoustomerInvoiceDetails from "./MerchantDashboard/MerchantDashboardCoustomerInvoiceDetails";
import ImagePopupModal from "./MerchantDashboard/ImagePopupModal";
import InsuranceHolderRentalConfirm from "./CustomerDashBoard/InsuranceHolderRentalConfirm";
import InsuranceDateComponent from "./CustomerDashBoard/InsuranceDate";
import Merchantdeposit from "./MerchantDashboard/MerchantDashboardDepositPenality";
import Merchantdepositsdetails from "./MerchantDashboard/MerchantDashboardDepositPenalityDetails";
//import MessagingComponent from "./MessagingComponent";
import ConfirmationModal from "./ConfirmationModal";

//------   garage dashboard ------------------
import GarageSideBar from "./GarageDashBoard/GarageSideBar";
import GarageNavBar from "./GarageDashBoard/GarageNavBar";
import GarageHome from "./GarageDashBoard/GarageHome";
import GarageInsuranceCalimList from "./GarageDashBoard/GarageInsuranceClaimList";
import GarageInsuranceOrderDetails from "./GarageDashBoard/GarageInsuranceOrderDetails";

//--------- garage onboard ----------
import GarageOnboardLeft from "./GarageOnboard/GarageOnboardLeft";
import LoginGarage from "./GarageOnboard/GarageLoginPage";
import GarageSignUpEmail from "./GarageOnboard/GarageSignupEmail";
import GarageSignUpViaPhone from "./GarageOnboard/GarageSignupViaPhone";
import GarageOtpPageViaPhone from "./GarageOnboard/GarageOtpPageViaPhone";
import GarageOtpScreen from "./GarageOnboard/GarageOtpPage";
import GaragePassword from "./GarageOnboard/GarageSignupPassword";
import GarageBusinessScreen from "./GarageOnboard/GarageSignupBusiness";
import GrageOnboardWelcome from "./GarageOnboard/GrageOnboardWelcome";
import GarageOnboardKYCDetail from "./GarageOnboard/GarageOnboardKYCDetail";
import GarageOnboardLicenseDetails from "./GarageOnboard/GarageOnboardLicenseDetails";
import GarageOnboardForgotPassWord from "./GarageOnboard/GarageOnboardForgotPassWord";
import GarageOnboardForgotOtp from "./GarageOnboard/GarageOnboardForgotOtp";
import GarageOnboardNewPassword from "./GarageOnboard/GarageOnboardNewPassword";

// -------------------- order details --------------
import Deliveredorder from "./MerchantDashboard/Deliveredorder";
import DeliveredorderDetails from "./MerchantDashboard/DeliveredorderDetails";
import AcceptedorderDetails from "./MerchantDashboard/AcceptedorderDetails";
import RecollectedOrder from "./MerchantDashboard/RecollectedOrder";
import RecollectedOrderDetails from "./MerchantDashboard/RecollectedOrderDetails";
import AcceptedorderDetailsEdit from "./MerchantDashboard/AcceptedorderDetailsEdit";
import DeliveryExecutiveAsign from "./MerchantDashboard/DeliveryExecutiveAsign";

//---------------------- customer dashboard -----------------------------------
import RentDealsDetails from "./CustomerDashBoard/RentDealsDetails";
import BookingCalender from "./CustomerDashBoard/BookingCalendar";
import CustomerDashBoardHowItWorks from "./CustomerDashBoard/CustomerDashBoardHowItWorks";
import MerchantNotifications from "./MerchantDashboard/MerchantNotifications";

//------------- afl --------------------------
import AflSidebar from "./AFL/sidebar";
import AflNavbar from "./AFL/navbar";
import AflMerchantOnboardLeftSide from "./AFL/logInLeftside";
import PageLoader from "./Loader/PageLoader";

export {
  CustomerInfo,
  CustomCheckBox,
  CustomerSignUp,
  RegistrationSuccessComponent,
  Progressbar,
  CustomInput,
  SuccessModal,
  ErrorModal,
  CreateProduct,
  CustomRadio,
  TimePicker,
  MerchantDashboardSidebar,
  MerchantDashboardnavbar,
  ManageCarList,
  MainPage,
  MerchantDashboardFullComponents,
  LocationStatus,
  CreateProductCarInfo,
  CreateProductInsurance,
  MapBox,
  MerchantManageMyCars,
  CustomerDashBoardMyAccount,
  CustomerDashBoardNavbar,
  CustomerDashBoardSidebar,
  ProductDetails,
  RentalSummary,
  FinalReport,
  VehicleInspection,
  InsuranceHolderVehicleInspection,
  NonInsuranceHolder,
  BookingProcess,
  MerchantOnboardLeftSide,
  NonInsuranceHolderSecondPage,
  RentalSummarySecondPage,
  RentalSummaryCarousel,
  ManageMyProfile,
  RentDeals,
  Customersettings,
  Merchantmanagemyprofile,
  Merchantsettings,
  CustomerOnboardLeftSide,
  CoustomerInvoiceList,
  CoustomerInvoiceDetails,
  Merchantdeposit,
  Merchantdepositsdetails,
  ImagePopupModal,
  RegistrationModal,
  InsuranceHolderRentalConfirm,
  InsuranceDateComponent,
  // MessagingComponent,
  ConfirmationModal,
  GarageSideBar,
  GarageNavBar,
  GarageHome,
  GarageInsuranceCalimList,
  GarageOnboardLeft,
  LoginGarage,
  GarageSignUpEmail,
  GarageSignUpViaPhone,
  GarageOtpPageViaPhone,
  GarageOtpScreen,
  GaragePassword,
  GarageBusinessScreen,
  GrageOnboardWelcome,
  GarageOnboardKYCDetail,
  GarageOnboardLicenseDetails,
  GarageOnboardForgotPassWord,
  GarageOnboardForgotOtp,
  GarageOnboardNewPassword,
  GarageInsuranceOrderDetails,
  Deliveredorder,
  DeliveredorderDetails,
  AcceptedorderDetails,
  RecollectedOrder,
  RecollectedOrderDetails,
  RentDealsDetails,
  BookingCalender,
  AcceptedorderDetailsEdit,
  DeliveryExecutiveAsign,
  CustomerDashBoardHowItWorks,
  MerchantNotifications,
  AflSidebar,
  AflNavbar,
  AflMerchantOnboardLeftSide,
  PageLoader
};
