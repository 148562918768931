import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import {
  kirkos_logo_black_yellow,
  car_sign_in,
  icon_normal,
} from "../../../assets/images";
import { CustomInput, ErrorModal } from "../../../components";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { LoginData } from "../../../types";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import "./SignInScreen.css";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../Redux/Auth/authSlice";
const CustomerSignUpFormBoot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showCredentialErrorModal, setShowCredentialErrorModal] =
    useState(false);
  // const [isChecked, setChecked] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  let access_token: string = "";
  let refresh_token: string = "";

  const handleLogin = useMutation({
    mutationFn: (userData: LoginData) =>
      axiosInstance.post(API_URL.LOGIN, userData),
    onSuccess: (successResponse: any) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        alert(successResponse?.data?.message);
      }

      access_token = successResponse?.data?.access_token;
      refresh_token = successResponse?.data?.refresh_token;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      saveTokenToLocalStorage();
      dispatch(loginSuccess());
      navigate("/customer/myaccount");
    },

    onError: (error: any) => {
      if (
        error?.data?.message ===
        "You are not registered with this mobile number"
      ) {
        setShowErrorModal(true);
      } else if (
        error?.data?.message === "The credentials you entered are invalid"
      ) {
        setShowCredentialErrorModal(true);
      }
    },
  });

  const saveTokenToLocalStorage = () => {
    if (access_token !== null && refresh_token !== null) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
    }
  };

  // const handleCheckBoxChange = () => {
  //   setChecked(!isChecked);
  //   if (isChecked) {
  //     localStorage.removeItem("access_token");
  //     localStorage.removeItem("refresh_token");
  //   } else {
  //     saveTokenToLocalStorage();
  //   }
  // };

  const onHandleSubmit = (data: any) => {
    const userData: LoginData = {
      username: data?.phoneNo,
      password: data?.password,
      userType: "CONSUMER",
    };

    handleLogin.mutate(userData);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="outer-container">
      <div className="container-fluid bg-black custom-container">
        {/* Main content row with two columns */}
        <div className="row onboard-garage-main ">
          {/* Left side with yellow color */}
          <div className="col-md-6 custom-yellow-bg ">
            {/* Logo in the top-left corner of the yellow part */}
            <div className="row ps-3">
              <div className="col-md-12 text-start mt-5">
                <img
                  src={kirkos_logo_black_yellow}
                  width="150"
                  height="150"
                  alt="Logo"
                  className="img-fluid image-logo-left-side"
                />
              </div>
              <div className="col-md-10 pt-4">
                <p className="text">Enjoy your Trips with our wheels</p>
              </div>
              <div className="col-md-12 pt-2">
                <p className="subtext">
                  Get ready to hit the road with confidence. Your next
                  generation adventure begins here.
                </p>
              </div>
              <div className="car-div">
                <img
                  src={car_sign_in}
                  className="car-img img-fluid"
                  alt="img"
                ></img>
              </div>
            </div>

            {/* Your content for the left side goes here */}
          </div>

          {/* Right side with black color */}
          <div className="col-md-6 customer_login_right text-light text-start ">
            <div className="ps-4 pt-5">
              <div className=" d-flex ">
                <img
                  src={icon_normal}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className=" customer_login_heading_text text-light ps-2 pt-3">
                  Customer Login
                </p>
              </div>
              <div className="d-flex  customer_login_subtext">
                <span className="customer_login_subtext pt-1">
                  New To KIRKOS?{" "}
                </span>
                <a href="/signup" className="customer_login_sign_up ps-3">
                  Sign up
                </a>
                <a href="/" className="customer_login_home ps-3">
                  Home{" "}
                </a>
              </div>

              <div className="row pt-5 pe-2">
                <div className="col-md-12">
                  <label className="form-label customer_login_enter_text">
                    Enter your mobile number
                  </label>
                  <div className="d-flex">
                    <span className="customer_login_country_code pe-1 ">
                      +971
                    </span>
                    <CustomInput
                      inputName="phoneNo"
                      inputType="tel"
                      className="customer_login_input"
                      placeholder="enter your number"
                      editable={true}
                      maxLength={9}
                      onInputChange={(value: string) => { }}
                      control={control}
                      rules={rules.AuthRules.phone_number}
                    />
                  </div>
                </div>

                <div className="col-md-12 pt-3">
                  <label className=" form-label customer_login_password_text ">
                    Password
                  </label>
                  <div className="d-flex">
                    <CustomInput
                      inputName="password"
                      inputType={showPassword ? "text" : "password"}
                      className="merchant_login_input_field "
                      placeholder="xxxxxx"
                      editable={true}
                      onInputChange={(value: string) => { }}
                      control={control}
                      rules={rules.AuthRules.password}
                    />
                    <span
                      className="fs-5 password_eye"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <EyeSlash /> : <Eye />}
                    </span>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="rememberMeContainer pt-5"> */}
                {/* <CustomCheckBox isChecked={false} onChange={function (): void {
                  throw new Error("Function not implemented.");
                } } /> */}
                {/* <CustomCheckBox
                      isChecked={isChecked}
                      onChange={handleCheckBoxChange}
                    />
                    <span className="rememberMeText">Remember me</span> */}
                {/* <a href="/forgotpassword" className="forgot-link ps-5">
                      Forgot Password?
                    </a>
                  </div>
                </div> */}

                {/* <div className="d-flex pt-5 "> */}
                {/* <CustomCheckBox
                  isChecked={isChecked}
                  onChange={handleCheckBoxChange}
                />

                <span
                  className="merchant_login_rememberme_text flex-nowrap d-flex"
                  style={{ textWrap: "nowrap" }}
                >
                  Remember me
                </span> */}
                {/* <span
                    className="flex-nowrap d-flex text-end"
                    style={{ width: "100%" }}
                  >
                    <a
                      href="/forgotpassword"
                      className="merchant_forgot_link"
                      style={{ textWrap: "nowrap", width: "100%" }}
                    >
                      Forgot Password?
                    </a>
                  </span>
                </div> */}

                <div className="col-12 text-end pt-5">
                  <a
                    href="/forgotpassword"
                    className="merchant_forgot_link"
                    style={{ textWrap: "nowrap", width: "100%" }}
                  >
                    Forgot Password?
                  </a>
                </div>

                {/* <div className="d-flex pt-5 "> */}
                {/* <CustomCheckBox
            isChecked={isChecked}
            onChange={handleCheckBoxChange}
          /> */}

                {/* <span
            className="merchant_login_rememberme_text flex-nowrap d-flex"
            style={{ textWrap: "nowrap" }}
          >
            Remember me
          </span> */}
                {/* </div> */}
                <div className="col-md-12">
                  <button
                    className="btn all_button_style text-black btn"
                    onClick={handleSubmit(onHandleSubmit)}
                    disabled={!isValid}
                  >
                    SIGN IN
                  </button>
                </div>
              </div>
            </div>

            <ErrorModal
              isOpen={showErrorModal}
              onClose={() => setShowErrorModal(false)}
              message="You are not registered"
            />

            <ErrorModal
              isOpen={showCredentialErrorModal}
              onClose={() => setShowCredentialErrorModal(false)}
              message="The credentials you entered are invalid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSignUpFormBoot;
