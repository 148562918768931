// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .customer_login_right,
  .customer_signup_righthalf,
  .customer_otp_righthalf,
  .custom_welcome_right_container {
    border: none !important;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .responsive-sidebar-ab {
    z-index: 100;
    position: absolute;
  }
}
@media (max-width: 540px) {
  .image-arrow-customer {
    display: none;
  }
}
@media only screen and (max-width: 430px) and (max-height: 932px) {
  .dashboardcontainer {
    max-width: 100dvw;
    max-height: 100dvh;
  }
  input,
  select {
    font-size: 16px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Css/responsive.css"],"names":[],"mappings":"AAAA;EACE;;;;IAIE,uBAAuB;IACvB,sBAAsB;EACxB;AACF;AACA;EACE;IACE,YAAY;IACZ,kBAAkB;EACpB;AACF;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;;IAEE,0BAA0B;EAC5B;AACF","sourcesContent":["@media (max-width: 768px) {\n  .customer_login_right,\n  .customer_signup_righthalf,\n  .customer_otp_righthalf,\n  .custom_welcome_right_container {\n    border: none !important;\n    width: 100% !important;\n  }\n}\n@media (max-width: 767px) {\n  .responsive-sidebar-ab {\n    z-index: 100;\n    position: absolute;\n  }\n}\n@media (max-width: 540px) {\n  .image-arrow-customer {\n    display: none;\n  }\n}\n@media only screen and (max-width: 430px) and (max-height: 932px) {\n  .dashboardcontainer {\n    max-width: 100dvw;\n    max-height: 100dvh;\n  }\n  input,\n  select {\n    font-size: 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
