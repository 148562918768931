import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import "./TimePickerComponent.scss";

interface TimePickerProps {
  onChange: (time: string | null) => void;
  label: string;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({ onChange, label }) => {
  const [open, setOpen] = useState(false);

  //--------------------------------------------------------------------------------------

  useEffect(() => {
    const handleMinuteClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.innerText === "00") {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("click", handleMinuteClick);
    } else {
      document.removeEventListener("click", handleMinuteClick);
    }

    return () => {
      document.removeEventListener("click", handleMinuteClick);
    };
  }, [open]);

  //----------------------------------------------------------------------------------------
 
  const handleSave = (newValue: Dayjs | null) => {
    if (newValue) {
      const formattedTime = newValue.format("HH:mm");
      onChange(formattedTime);
  
      const minutes = newValue.format("mm"); 
      if (minutes !== "00") {
        setTimeout(() => {
          setOpen(false);
        }, 500); 
        return;
      }
    } else {
      onChange(null);
    }
  
    setOpen(false); 
    };
  
  return (
    <div className="time-picker-container pt-2" onClick={() => setOpen(true)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label}
          views={["hours", "minutes"]}
          ampm={false}
          open={open}
          onClose={() => setOpen(false)}
          
          onChange={(newValue) => handleSave(newValue)}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onClick: () => setOpen(true),
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerComponent;
