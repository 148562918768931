import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { kirkos_logo_black_yellow } from '../../../assets/images';
import "./apkhome.scss";
import { Link } from 'react-router-dom';

const ApkHome: React.FC = () => {
    return (
        <div className='apk-home'>
            <div className='apk-home-bg' />
            <div className='apk-home-bg-bottom' />
            <div className="apk-home-bg-img" />
            <div className='pt-5'>
                <img src={kirkos_logo_black_yellow} alt="" />
            </div>
            <div className='navigate-btn-apk'>
                <div className='responsive-text-apk'>
                    RENT A CAR & MAKE YOUR <br /> TRIP ENJOYABLE
                </div>
                <div className='padding-top-navigate-btn'>
                    <Link to="/signup">
                        <Button className='apk-home-button-style'>
                            SIGN UP
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/signin">
                        <Button className='apk-home-button-style'>
                            SIGN IN
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ApkHome