import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SignInScreen, SignUpScreen } from "../Pages/Customer";
import HomePage from "../Pages/Customer/homepage";
import CustomerSignUpFormBoot from "../Pages/Customer/signupboot";
import OtpScreen from "../components/otp";
import WelcomePage from "../components/welcomepage";
import SignUpDetails from "../Pages/Customer/signupdetails";
import Welcome from "../components/welcome";
import MerchantSignUp from "../Pages/Merchant/MerchantSignUpFIrstPage";
import Password from "../Pages/Merchant/MerchantSignUpSecondPage/MerchantPassword";
import WelcomeMerchant from "../Pages/Merchant/MerchantWelcomePage/MerchantWelcome";
import LoginKirkos from "../Pages/Merchant/MerchantLoginPage/MerchantKirkoslogin";
import KYCdetails from "../Pages/Merchant/KYCdetails";
import LicenseDetails from "../Pages/Merchant/licensedetails";
import MerchantSignUpFormBoot from "../Pages/Merchant/MerchantSignUpFIrstPage";
import MerchantOtpScreen from "../Pages/Merchant/MerchantOtpPage.tsx/MerchantOtp";
import MerchantBusinessScreen from "../Pages/Merchant/MerchantSignUpThirdPage/MerchantBusiness";
import MerchantSignUpViaPhone from "../Pages/Merchant/MerchantSignUpViaPhone";
import MerchantOtpScreenViaPhone from "../Pages/Merchant/MerchantOtpPageViaPhone";
import MerchantDashboard from "../Pages/Merchant/MerchantDashboard";
import PaymentSuccess from "../components/PaymentSuccess";
import PaymentFailed from "../components/PaymentFailed";
import {
  CreateProductCarInfo,
  CreateProductInsurance,
  LocationStatus,
  CustomerDashBoardMyAccount,
  MainPage,
  InsuranceHolderVehicleInspection,
  FinalReport,
  VehicleInspection,
  ProductDetails,
  BookingProcess,
  NonInsuranceHolder,
  NonInsuranceHolderSecondPage,
  RentalSummarySecondPage,
  ManageMyProfile,
  RentalSummaryCarousel,
  RentDeals,
  Merchantmanagemyprofile,
  Merchantsettings,
  CoustomerInvoiceList,
  CoustomerInvoiceDetails,
  InsuranceHolderRentalConfirm,
  Merchantdeposit,
  Merchantdepositsdetails,
  GarageHome,
  LoginGarage,
  GarageSignUpEmail,
  GarageSignUpViaPhone,
  GarageOtpScreen,
  GaragePassword,
  GarageBusinessScreen,
  GrageOnboardWelcome,
  GarageOnboardKYCDetail,
  GarageOnboardLicenseDetails,
  GarageOnboardForgotPassWord,
  GarageOnboardForgotOtp,
  GarageOnboardNewPassword,
  GarageOtpPageViaPhone,
  GarageInsuranceOrderDetails,
  GarageInsuranceCalimList,
  Deliveredorder,
  DeliveredorderDetails,
  AcceptedorderDetails,
  RecollectedOrder,
  RecollectedOrderDetails,
  RentDealsDetails,
  BookingCalender,
  AcceptedorderDetailsEdit,
  DeliveryExecutiveAsign,
  MerchantNotifications,
} from "../components";
import { ManageCarList } from "../components";
import { MerchantDashboardFullComponents } from "../components";

import CustomerDashboard from "../Pages/Customer/CustomerDashboard";
import CustomerForgotPassword from "../Pages/Customer/forgotpassword";
import Customerotpviaforgot from "../Pages/Customer_Otp_ViaForgot";
import Customernewpassword from "../Pages/Customer_Newpassword";

import MerchantForgotPassword from "../Pages/Merchant/MerchantForgotPassword";
import MerchantDashboardMyIncome from "../components/MerchantDashboard/MerchantDashboardMyIncome";
import MerchantManageMyCars from "../components/MerchantDashboard/MerchantManageMyCars";
import MerchantOtpViaForgot from "../Pages/Merchant/MerchantOtpViaForgot";
import MerchantNewPassword from "../Pages/Merchant/MerchantNewpassword";
import SelectPaymentMethod from "../components/SelectPaymentMethod";
import Odoo from "../Pages/Merchant/Odoo";
import ProcessPayment from "../components/ProcessPayment";
import CustomerNotification from "../components/CustomerDashBoardNotifications";
import CustomerSettings from "../components/CustomerDashBoard/CustomerSettings";
import DeliveryBoyList from "../components/MerchantDashboard/DeliveryBoyList";
import DeliveryBoyDetails from "../components/MerchantDashboard/DeliveryBoyDetails";
import ApprovedDeliveryBoysList from "../components/MerchantDashboard/ApprovedDeliveryBoysList";
import AssignedOrderList from "../components/MerchantDashboard/AssignedOrderList";
import TrackOrderDetails from "../components/MerchantDashboard/TrackOrderDetails";
import ApprovedOrders from "../components/MerchantDashboard/ApprovedOrders";
import GarageDashBoard from "../Pages/Garage/GarageDashBoard";
import GarageOnboard from "../Pages/Garage/GarageOnboard";
import RecollectionDeliveryBoysList from "../components/MerchantDashboard/RecollectionDeliveryBoysList";
import GarageProfile from "../components/GarageDashBoard/GarageProfile";
import LoginAFL from "../Pages/AFL/AFL_Login/AFLMerchantLogin";
import AflHome from "../Pages/AFL/aflhome";
import ServiceList from "../Pages/AFL/ServiceList";
import ServiceCreation from "../Pages/AFL/ServiceCreation";
import AflDashboard from "../Pages/AFL/dashboard/AflDashboard";
import AflDashboardHome from "../Pages/AFL/dashboardhome";
import ServiceDetails from "../Pages/AFL/ServiceDetails";
import RescheduleDate from "../components/AFL/RescheduleDate";

const RouterComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/merchant/signup" element={<MerchantSignUpFormBoot />} />
        <Route
          path="/merchant/signup/viaphone"
          element={<MerchantSignUpViaPhone />}
        />
        <Route
          path="/merchant/signup/otpmerchant/viaphone"
          element={<MerchantOtpScreenViaPhone />}
        />
        <Route
          path="/merchant/signup/otpmerchant"
          element={<MerchantOtpScreen />}
        />
        <Route path="/merchant/signup/password" element={<Password />} />
        <Route
          path="/merchant/signup/businesspage"
          element={<MerchantBusinessScreen />}
        />
        <Route path="/customer/noninsurance" element={<NonInsuranceHolder />} />
        <Route
          path="/merchant/signup/licensedetails"
          element={<LicenseDetails />}
        />
        <Route path="/merchant/signup/kycdetails" element={<KYCdetails />} />
        <Route path="/merchant/signup/welcome" element={<WelcomeMerchant />} />

        <Route
          path="/merchant/signup/businesspage"
          element={<MerchantBusinessScreen />}
        />
        <Route path="/merchantsignup" element={<MerchantSignUp />} />
        <Route path="/signin" element={<SignInScreen />} />
        <Route path="/signinmerchant" element={<LoginKirkos />} />
        <Route path="/odoo" element={<Odoo />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/signup" element={<CustomerSignUpFormBoot />} />
        <Route path="signup/otpscreen" element={<OtpScreen />} />
        <Route path="forgot/otpscreen" element={<Customerotpviaforgot />} />
        <Route path="/forgotpassword" element={<CustomerForgotPassword />} />
        <Route path="/newpassword" element={<Customernewpassword />} />
        <Route path="/merchant/password" element={<Password />} />
        <Route path="/merchant/welcome" element={<WelcomeMerchant />} />
        <Route path="/merchant/login" element={<LoginKirkos />} />
        <Route
          path="/merchant/income"
          element={<MerchantDashboardMyIncome />}
        />
        <Route path="/merchant/manage" element={<MerchantManageMyCars />} />
        <Route
          path="/merchant/forgot/otppage"
          element={<MerchantOtpViaForgot />}
        />
        <Route path="/merchant/newpassword" element={<MerchantNewPassword />} />

        <Route
          path="/merchant/income"
          element={<MerchantDashboardMyIncome />}
        />
        <Route
          path="signup/otpscreen/signupdetails"
          element={<SignUpDetails />}
        />
        <Route
          path="signup/otpscreen/signupdetails/welcome"
          element={<WelcomePage />}
        />
        <Route path="/signin/home" element={<Welcome />} />
        <Route
          path="/merchant/forgotpassword"
          element={<MerchantForgotPassword />}
        />

        {/* <Route path="/MerchantDashboard" element={<MerchantDashboard />} /> */}
        <Route path="/merchant" element={<MerchantDashboard />}>
          <Route
            path="/merchant/home"
            element={<MerchantDashboardFullComponents />}
          />
          <Route path="/merchant/managecars" element={<ManageCarList />} />
          <Route path="/merchant/listmycar" element={<ManageCarList />} />

          <Route
            path="/merchant/managecars/carinfo"
            element={<CreateProductCarInfo />}
          />

          <Route
            path="/merchant/managecars/insurance"
            element={<CreateProductInsurance />}
          />
          <Route
            path="/merchant/managecars/list/:productId"
            element={<MerchantManageMyCars />}
          />
          <Route path="/merchant/locationstatus" element={<LocationStatus />} />
          <Route
            path="/merchant/managemyprofile"
            element={<Merchantmanagemyprofile />}
          />
          <Route path="/merchant/settings" element={<Merchantsettings />} />

          {/* ------------ order listing -------------- */}
          <Route path="/merchant/invoice" element={<CoustomerInvoiceList />} />
          <Route path="/merchant/approvedorders" element={<ApprovedOrders />} />
          <Route path="/merchant/deliveredorder" element={<Deliveredorder />} />
          <Route
            path="/merchant/recollectedorder"
            element={<RecollectedOrder />}
          />
          <Route
            path="/merchant/notificationlist"
            element={<MerchantNotifications />}
          />
          {/* ------------ order listing -------------- */}
          {/* ------------ order details --------------------- */}
          <Route
            path="/merchant/invoice/details"
            element={<CoustomerInvoiceDetails />}
          />
          <Route
            path="/merchant/accepted/details"
            element={<AcceptedorderDetails />}
          />
          <Route
            path="/merchant/accepted/edit"
            element={<AcceptedorderDetailsEdit />}
          />
          <Route
            path="/merchant/accepted/assign"
            element={<DeliveryExecutiveAsign />}
          />
          <Route
            path="/merchant/delivered/details"
            element={<DeliveredorderDetails />}
          />
          <Route
            path="/merchant/recollected/details"
            element={<RecollectedOrderDetails />}
          />

          {/* ------------ order details --------------------- */}

          <Route
            path="/merchant/depositandpenality"
            element={<Merchantdeposit />}
          />
          <Route
            path="/merchant/depositandpenality/details"
            element={<Merchantdepositsdetails />}
          />
          <Route
            path="/merchant/trackorderdetails"
            element={<TrackOrderDetails />}
          />
          <Route
            path="/merchant/deliveryboylist"
            element={<DeliveryBoyList />}
          />
          <Route
            path="/merchant/deliveryboylist/details/:customerId"
            element={<DeliveryBoyDetails />}
          />
          <Route
            path="/merchant/approveddeliveryboyslist"
            element={<ApprovedDeliveryBoysList />}
          />
          <Route
            path="/merchant/recollectioneliveryboyslist/assign"
            element={<RecollectionDeliveryBoysList />}
          />
          <Route
            path="/merchant/assignedorderlist"
            element={<AssignedOrderList />}
          />
        </Route>

        <Route path="/customer" element={<CustomerDashboard />}>
          <Route
            path="/customer/insuranceholdervehicleinspection"
            element={<InsuranceHolderVehicleInspection />}
          />
          <Route path="/customer/finalreport" element={<FinalReport />} />
          <Route
            path="/customer/rentalconfirm"
            element={<InsuranceHolderRentalConfirm />}
          />
          <Route
            path="/customer/vehicleinspection"
            element={<VehicleInspection />}
          />
          <Route
            path="/customer/noninsuranceholder"
            element={<NonInsuranceHolder />}
          />
          <Route path="/customer/processpayment" element={<ProcessPayment />} />

          <Route
            path="/customer/notifications"
            element={<CustomerNotification />}
          />
          <Route
            path="/customer/selectpayment"
            element={<SelectPaymentMethod />}
          />

          <Route
            path="/customer/bpnonholder"
            element={<NonInsuranceHolder />}
          />
          <Route
            path="/customer/noninsurence"
            element={<NonInsuranceHolderSecondPage />}
          />
          <Route path="/customer/bookingprocess" element={<BookingProcess />} />

          <Route
            path="/customer/managemyprofile"
            element={<ManageMyProfile />}
          />
          <Route path="/customer/rentdeals" element={<RentDeals />} />
          <Route
            path="/customer/rentdealsdetails"
            element={<RentDealsDetails />}
          />

          <Route
            path="/customer/myaccount"
            element={<CustomerDashBoardMyAccount />}
          />
          <Route path="/customer/settings" element={<CustomerSettings />} />
          <Route
            path="/customer/bookingcalender"
            element={<BookingCalender />}
          />
        </Route>
        <Route path="/customer/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/customer/paymentfailed" element={<PaymentFailed />} />
        {/* ------------------------ Garage-------------------------------------- */}
        <Route path="/garage" element={<GarageDashBoard />}>
          <Route index element={<GarageHome />} />
          <Route
            path="/garage/insuranceorderdetails"
            element={<GarageInsuranceOrderDetails />}
          />
          <Route
            path="/garage/insuranceorder"
            element={<GarageInsuranceCalimList />}
          />
          <Route path="/garage/garageprofile" element={<GarageProfile />} />
        </Route>
        {/* ---------------------------Garage------------------------------------ */}
        {/* ------------------------ Garage-------------------------------------- */}
        <Route path="/garageonboard" element={<GarageOnboard />}>
          <Route index element={<LoginGarage />} />
          <Route path="/garageonboard/email" element={<GarageSignUpEmail />} />
          <Route path="/garageonboard/otpEmail" element={<GarageOtpScreen />} />
          <Route
            path="/garageonboard/viaphone"
            element={<GarageSignUpViaPhone />}
          />
          <Route
            path="/garageonboard/otpviaphone"
            element={<GarageOtpPageViaPhone />}
          />
          <Route
            path="/garageonboard/garagepassword"
            element={<GaragePassword />}
          />
          <Route
            path="/garageonboard/business"
            element={<GarageBusinessScreen />}
          />
          <Route
            path="/garageonboard/licensedetails"
            element={<GarageOnboardLicenseDetails />}
          />

          <Route
            path="/garageonboard/kycdetails"
            element={<GarageOnboardKYCDetail />}
          />

          <Route
            path="/garageonboard/welcome"
            element={<GrageOnboardWelcome />}
          />
          <Route
            path="/garageonboard/forgotpassWord"
            element={<GarageOnboardForgotPassWord />}
          />
          <Route
            path="/garageonboard/forgototp"
            element={<GarageOnboardForgotOtp />}
          />
          <Route
            path="/garageonboard/newpassword"
            element={<GarageOnboardNewPassword />}
          />
        </Route>
        {/* ---------------------------Garage------------------------------------ */}
        <Route path="/afl" element={<AflHome />}>
          <Route index element={<LoginAFL />} />
        </Route>
        <Route path="/dashboard" element={<AflDashboard />}>
          <Route index element={<AflDashboardHome />} />
          <Route path="/dashboard/servicelist" element={<ServiceList />} />
          <Route
            path="/dashboard/servicecreation"
            index
            element={<ServiceCreation />}
          />
          <Route
            path="/dashboard/servicelist/servicedetails"
            element={<ServiceDetails />}
          />
          <Route
            path="/dashboard/servicelist/rescheduledate"
            element={<RescheduleDate />}
          />
          <Route
            path="/dashboard/myprofile"
            element={<Merchantmanagemyprofile />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterComponent;
