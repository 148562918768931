import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { thumb_up } from "../../assets/images";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("order_number");

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(`/customer/rentdealsdetails?OrderId=${OrderId}`);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="d-flex flex flex-col align-items-center justify-content-center  vh-100  justify-center bg-gray-900 text-center px-4 dashboard-poppins-font-style dashboardright">
      <div className=" bg-black text-white max-w-md col-md-8 col-lg-6 w-full p-3 rounded-3">
        <div className="mb-6">
          <img
            src={thumb_up}
            width="120"
            height="120"
            alt="Success"
            className="animate-bounce mb-6"
          />
        </div>
        <div className="p-8">
          <h1 className="text-3xl font-bold">Payment Success! 🎉</h1>

          <p className="text-yellow-400 font-semibold text-lg mt-2">
            Order ID: {OrderId}
          </p>
          <p className="text-gray-300 mt-2">
            Thank you. Redirecting shortly...
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
