import { Stars } from "react-bootstrap-icons";
import {
  group_icon,
  petrol_pump,
  rental_car,
  stars,
  mileage,
  star,
} from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setBookingValues } from "../../../Redux/BookingData/bookingDataSlice";
import { json } from "stream/consumers";
import React, { useEffect, useState } from "react";
interface RentalSummaryProps {
  daysDifference: number;
  totalAmount: number;
  childSeatAmount: number;
  paiInsuranceAmount: number;
  cdwAmount: number;
  scdwAmount: number;
  carImage: string;
  carModel: string;
  seat: number;
  fuelType: string;
  deliveryFee: number;
  isLastPage: boolean;
  wtpAmount: number;

  onDataFromRentalSummary: (data: any) => void;
}

const RentalSummarySecondPage: React.FC<RentalSummaryProps> = ({
  daysDifference,
  totalAmount,
  childSeatAmount,
  paiInsuranceAmount,
  cdwAmount,
  scdwAmount,
  carImage,
  carModel,
  seat,
  fuelType,
  deliveryFee,
  wtpAmount,
  onDataFromRentalSummary,
  isLastPage,
}: RentalSummaryProps) => {
  const dispatch = useDispatch();
  //const [totalRentalPrice, setTotalRentalPrice] = useState(0);
  const { bookingValues } = useSelector((state: any) => state.bookingValues);
  console.log(childSeatAmount);

  const [totalRentalPrice, setTotalRentalPrice] = useState<number>(
    bookingValues?.totalRentalPrice || 0
  );
  const bookingValuesConstant = {
    SCDW: "SCDW",
    CDW: "CDW",
    No: "No",
  };
  console.log(
    "booking values_____",
    bookingValuesConstant["No"],
    JSON.stringify(bookingValues)
  );

  const calculateTotal = async () => {
    let total = totalAmount;

    if (bookingValues.babySeatSelected) {
      total += childSeatAmount;
    }

    if (bookingValues.additionalDriverSelected) {
      total += 35;
    }
    if (bookingValues.wtpSelected) {
      total += wtpAmount;
    }
    if (bookingValues.cdwSelected) {
      total += cdwAmount;
    }
    if (bookingValues.scdwSelected) {
      total += scdwAmount;
    }

    if (bookingValues.isCarRentalAllowed) {
      total += paiInsuranceAmount || 0;
    }

    // if (bookingValues.insuranceType === "SCDW") {
    //   total += scdwAmount || 0;
    // } else if (bookingValues.insuranceType === "CDW") {
    //   total += cdwAmount || 0;
    // }
    if (bookingValues.deliveryAmount === bookingValuesConstant["No"]) {
      total += deliveryFee || 0;
      console.log("delivery amount", total);
      // alert("total calculated");
    }
    // setTotalRentalPrice(total);
    // dispatch(
    //   setBookingValues({
    //     ...bookingValues,
    //     totalRentalPrice: total,
    //   })
    // );
    if (total !== totalRentalPrice) {
      setTotalRentalPrice(total);
      dispatch(setBookingValues({ ...bookingValues, totalRentalPrice: total }));
    }
  };

  // useEffect(() => {
  //   calculateTotal();
  // }, [bookingValues]);

  const debounce = (func: () => void, delay: number) => {
    let timeout: NodeJS.Timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(func, delay);
    };
  };

  const debouncedCalculateTotal = debounce(calculateTotal, 300);

  useEffect(() => {
    debouncedCalculateTotal();
  }, [bookingValues]);

  // useEffect(() => {
  //   if (bookingValues.selectedOptions === "NO") {
  //     calculateTotal();
  //   }
  // }, [bookingValues.selectedOptions]);
  // useEffect(() => {
  //   console.log("Selected options:", bookingValues.deliveryAmount);
  //   if (bookingValues.deliveryAmount === "No") {
  //     console.log("Inside NO condition");
  //     calculateTotal();
  //   }
  // }, [bookingValues.deliveryAmount]);

  return (
    <div className="col-md-12">
      <div className="card card-custom">
        <div className="card-body">
          <div className="text-white">
            <div className="col-md-12 text-style">
              <h5> Rental Summary </h5>
            </div>
            <div
              className="col-md-9 text-colour dashboard-poppins-font-style fontsize3"
              style={{ textAlign: "left" }}
            >
              Price may change if you exceed 1 hour within the agreed time{" "}
            </div>
            <div className="row pt-2">
              <div className="col-md-6 car-style">
                <img
                  /// src={rental_car}
                  src={carImage}
                  alt="file_browse_icon"
                  className="file_browse_icon"
                />
              </div>
              <div className="col-md-6 text-start ">
                <div className="rent-carmodel" style={{ marginTrim: "none" }}>
                  {carModel}
                </div>
                <img src={stars} alt="img-fluid" className="img-fluid" />
                <div style={{ color: "yellow", textDecoration: "underline" }}>
                  20+ Comments
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-12 text-start">
                <div className="col-md-12 text-start  pb-2 dashboard-poppins-font-style  car-name-search-bold">
                  Features
                </div>
                <div className="d-flex">
                  <div className="">
                    <img
                      src={group_icon}
                      alt="file_browse_icon"
                      className="img-fluid"
                    />
                    <span className="text-colour ps-1">{seat}</span>
                  </div>
                  <div className="ms-4">
                    <img
                      src={petrol_pump}
                      alt="file_browse_icon"
                      className="img-fluid img-width-icon"
                    />
                    <span className="text-colour dashboard-poppins-font-style">
                      {fuelType}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 pt-1">
                  <img
                    src={mileage}
                    alt="file_browse_icon"
                    className="img-fluid img-width-icon"
                    style={{ alignItems: "left" }}
                  />
                  <span className="text-colour ps-1 dashboard-poppins-font-style">
                    Unlimited Mileage
                  </span>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <table className="text-start table-width-custom table-rentalsummery table-amount">
                  <tbody>
                    <tr className="fontsize ">
                      <td className="reantal-td">
                        Subtotal{" "}
                        <span style={{ color: "grey" }}>
                          ({daysDifference} Days)
                        </span>
                      </td>
                      <td>AED {totalAmount}</td>
                    </tr>
                    {bookingValues.babySeatSelected && (
                      <tr className="fontsize ">
                        <td className="reantal-td">Extra Baby Seat</td>
                        <td>AED {childSeatAmount}</td>
                      </tr>
                    )}
                    {bookingValues.additionalDriverSelected && (
                      <tr className="fontsize ">
                        <td className="reantal-td">Additional Driver</td>
                        <td>AED 35</td>
                      </tr>
                    )}
                    {bookingValues.isCarRentalAllowed && (
                      <tr className="fontsize ">
                        <td className="reantal-td">PAI Insurance Daily</td>
                        <td>AED {paiInsuranceAmount || 0}</td>
                      </tr>
                    )}

                    {bookingValues.cdwSelected && (
                      <tr className="fontsize ">
                        <td className="reantal-td">CDW Insurance</td>
                        <td>AED {cdwAmount || 0}</td>
                      </tr>
                    )}
                    {bookingValues.scdwSelected && (
                      <tr className="fontsize ">
                        <td className="reantal-td">SCDW Insurance</td>
                        <td>AED {scdwAmount || 0}</td>
                      </tr>
                    )}

                    {bookingValues.wtpSelected && (
                      <tr className="fontsize ">
                        <td className="reantal-td">Wind Shield Protection</td>
                        <td>AED {wtpAmount}</td>
                      </tr>
                    )}

                    {/* <br /> */}
                    {/* {bookingValues.insuranceType === "SCDW" && (
                      <tr className="fontsize ">
                        <td className="reantal-td">
                          SCDW Insurance{" "}
                          <span style={{ color: "grey", whiteSpace: "nowrap" }}>
                            ({daysDifference} Days)
                          </span>
                        </td>
                        <td>AED {scdwAmount || 0}</td>
                      </tr>
                    )}

                    {bookingValues.insuranceType === "CDW" && (
                      <tr className="fontsize ">
                        <td className="reantal-td">
                          CDW Insurance{" "}
                          <span style={{ color: "grey", whiteSpace: "nowrap" }}>
                            ({daysDifference} Days)
                          </span>
                        </td>
                        <td>AED {cdwAmount || 0}</td>
                      </tr>
                    )} */}

                    {/* <br /> */}
                    {bookingValues.deliveryAmount === "No" && (
                      <tr className="fontsize ">
                        <td className="reantal-td">
                          Charges deliver by kirkos executive{" "}
                          <span style={{ color: "grey" }}>
                            {/* ({daysDifference} Days) */}
                          </span>
                        </td>
                        <td>AED {deliveryFee || 0}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <table className="text-start table-width-custom table-rentalsummery table-total-amount">
                  <tbody>
                    <tr className="fontsize2">
                      <td className="reantal-td">Total Rental Price</td>
                      <td>AED {totalRentalPrice}</td>
                    </tr>
                    {isLastPage && (
                      <tr className="fontsize2">
                        <td className="reantal-td">VAT</td>
                        <td>AED {(totalRentalPrice * 0.05).toFixed(2)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {isLastPage && (
              <div className="row pt-3">
                <div className="col-md-12">
                  <table className="text-start table-width-custom table-rentalsummery grand-table-total-amount">
                    <tbody>
                      <tr className="fontsize2 ">
                        <td className="reantal-td">Grand Total</td>
                        <td>
                          AED{" "}
                          {(totalRentalPrice + totalRentalPrice * 0.05).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalSummarySecondPage;
