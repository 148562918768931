import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import "./TimePickerComponent.css";

interface TimePickerProps {
  onChange: (time: string | null) => void;
  label: string;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({ onChange, label }) => {
  const [open, setOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState<number | null>(null);

  const handleTimeChange = (newValue: Dayjs | null) => {
    if (!newValue) return;

    if (selectedHour === null) {
      // First click: Selecting hour, keep it open
      setSelectedHour(newValue.hour());
    } else {
      // Second click: Selecting minutes, close picker
      onChange(newValue.format("HH:mm"));
      setOpen(false);
      setSelectedHour(null); // Reset for next time
    }
  };

  return (
    <div className="time-picker-container pt-2">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={label}
          views={["hours", "minutes"]}
          ampm={false}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            setSelectedHour(null);
          }}
          onChange={handleTimeChange}
          onAccept={() => setOpen(false)} // Close when user confirms
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onClick: () => setOpen(true),
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerComponent;
