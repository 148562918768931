import { dashboard_vertical_three_dots } from "../../../assets/images";

import { Link } from "react-router-dom";
import "./styles.css";
import { useState } from "react";

import { Dropdown, Pagination } from "react-bootstrap";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { BookedOrderApiResponse, BookedOrder } from "../../../types";

const RentDeals = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const fetchBookedOrders = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.BOOKED_ORDERS}?page=${page}&size=10`,
      {
        headers: {
          location: "0,0",
          language: "english",
        },
      }
    );
    return response?.data;
  };
  const { data: BookedOrders } = useQuery<BookedOrderApiResponse>({
    queryKey: ["BookedOrders", currentPage],
    queryFn: () => fetchBookedOrders(currentPage),
    refetchInterval: 30000,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  };

  //const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row pt-3">
          <div className="col-12 dashboard-table-div">
            <table className="table table-hover dashboard-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="min-width-th">Order Number</th>
                  <th>Rental Days</th>
                  <th className="min-width-th">Progress</th>

                  <th className="min-width-th">Product Name</th>
                  <th>Claim Number</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {BookedOrders?.content?.map(
                  (bookedorder: BookedOrder, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{bookedorder?.orderId}</td>

                      <td>
                        {bookedorder?.orderStatus === "BOOKED"
                          ? "Not Provided"
                          : bookedorder?.rental_days}
                      </td>
                      <td>
                        {" "}
                        <div
                          className={`align-content-center text-center ${bookedorder?.orderStatus === "ORDER_ACCEPT" ||
                            bookedorder?.orderStatus ===
                            "MERCHANT_ORDER_ACCEPT" ||
                            bookedorder?.orderStatus ===
                            "MERCHANT_ORDER_REJECTED"
                            ? "progress_status2"
                            : bookedorder?.orderStatus === "DELIVERED"
                              ? "progress_status1"
                              : bookedorder?.orderStatus === "BOOKED"
                                ? "progress_status3"
                                : bookedorder?.orderStatus === "ORDER_REJECTED"
                                  ? "progress_status5"
                                  : "progress_status4"
                            }`}
                        >
                          {/* {bookedorder?.orderStatus} */}
                          {bookedorder?.orderStatus === "ORDER_ACCEPT" ||
                            bookedorder?.orderStatus ===
                            "MERCHANT_ORDER_ACCEPT" ||
                            bookedorder?.orderStatus === "MERCHANT_ORDER_REJECTED"
                            ? "ORDER ACCEPTED"
                            : bookedorder?.orderStatus}
                        </div>
                      </td>
                      {/* <td>{bookedorder?.products?.[0]?.productName}</td> */}
                      <td className="text-end">
                        {bookedorder?.orderStatus === "BOOKED"
                          ? "Not Provided"
                          : bookedorder?.products?.[0]?.productName}
                      </td>
                      <td>{bookedorder?.insurance_claim}</td>
                      <td>
                        <span className="invoice-date-td">
                          {formatDate(bookedorder?.createdAt)}
                        </span>
                      </td>

                      <td>
                        {" "}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="Dropdown-table"
                          >
                            <img
                              alt="dashboard_vertical_three_dots"
                              src={dashboard_vertical_three_dots}
                              width="15"
                              height="15"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="Dropdown-table-item">
                            <Dropdown.Item className="Dropdown-table-list Dropdown-table-border-bottom">
                              <Link
                                to={`/customer/rentdealsdetails?OrderId=${bookedorder.orderId}`}
                                className="nav-link Dropdown-table-item-link"
                              // onClick={() => handleOrderDetails(invoice.orderId)}
                              >
                                View Details
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {BookedOrders && (
            <div className="col-12 text-end">
              <Pagination className="pagination-align">
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0}
                />
                {Array.from({ length: BookedOrders.totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index === currentPage}
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === BookedOrders.totalPages - 1}
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentDeals;
