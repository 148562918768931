import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_logo,
} from "../assets/images";
import CustomInput from "./CustomInput";
import Progressbar from "./Progressbar";
import { useForm } from "react-hook-form";
import rules from "../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../services/axios.instance";
import { API_URL } from "../services/apiConfig";
import { UserData, VerifyOtp } from "../types";
import "./otp.css";
import { useLocation, useNavigate } from "react-router-dom";

import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import CustomerOnboardLeftSide from "./CustomerOnboardLeftSide";
const OtpScreen = () => {
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpRefs] = useState<any>([useRef(), useRef(), useRef(), useRef()]);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const { state } = useLocation();
  const phoneNum = state && state.phoneNum;
  const transactionId = sessionStorage.getItem("transactionId");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();
  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0) {
      e.target.value = null;
      otpRefs[index - 1].current.focus();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });
  const handleOtpVerify = () => {
    const payLoad: VerifyOtp = {
      phoneNo: phoneNum,
      transactionId,
      otp: "1111",
      userType: "CONSUMER",
      event: "register",
    };
    verifyOtp.mutate(payLoad);
    console.log("transactionId", transactionId);
  };
  const verifyOtp = useMutation({
    mutationFn: (userData: VerifyOtp) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setIsOtpVerified(true);
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        alert(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  const handleResendOtp = () => {
    const userData: UserData = {
      phoneNo: phoneNum,
      userType: "CONSUMER",
      event: "register",
    };

    resendOtp.mutate(userData);
  };

  const goBackward = () => {
    navigate("signup");
  };

  const resendOtp = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseResendOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });
  return (
    <div className="outer-container">
      <div className="container-fluid bg-black custom-container">
        {/* Main content row with two columns */}
        <div className="row onboard-garage-main ">
          {/* Left side with yellow color */}
          <CustomerOnboardLeftSide />

          {/* Right side with black color */}
          <div className="col-md-6 text-light customer_otp_righthalf text-start">
            <div className="progress-bar">
              <Progressbar currentStage={2} />
            </div>
            <div className="ps-4 pt-5">
              <div className="icon_text d-flex align-items-center pt-4 ">
                <img
                  src={icon_normal}
                  className="icon-img img-fluid"
                  alt="Icon"
                />
                <p className=" customer_otp_headtext text-light mb-3">
                  OTP Verification
                </p>
              </div>
              <p className=" customer_otp_subtext pt-2 ">
                We will send you a one time password on this phone number +971{" "}
                {phoneNum}
              </p>

              <p className=" customer_otp_subtexttwo pt-4 ">
                OTP Default detected from your phone
              </p>
              <div className="col-md-12">
                <div className="otp_container_style pt-1">
                  <input
                    type="tel"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(0, e)}
                    onKeyDown={(e) => handleOtpKeyDown(0, e)}
                    ref={otpRefs[0]}
                  />
                  <input
                    type="tel"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(1, e)}
                    onKeyDown={(e) => handleOtpKeyDown(1, e)}
                    ref={otpRefs[1]}
                  />
                  <input
                    type="tel"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(2, e)}
                    onKeyDown={(e) => handleOtpKeyDown(2, e)}
                    ref={otpRefs[2]}
                  />
                  <input
                    type="tel"
                    className="otpBoxStyle"
                    placeholder="0"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(3, e)}
                    onKeyDown={(e) => handleOtpKeyDown(3, e)}
                    ref={otpRefs[3]}
                  />
                </div>
              </div>
              <div className="pt-4">
                <button
                  className="btn all_button_style text-black mt-2"
                  onClick={handleSubmit(handleOtpVerify)}
                  disabled={!isValid}
                >
                  VERIFY
                </button>
              </div>
              <div className="d-flex customer_otp_bottom_text ">
                <p>
                  Didn't receive the code?{" "}
                  <a
                    className="customer_otp_resend_link"
                    onClick={handleResendOtp}
                  >
                    RESEND
                  </a>
                </p>
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="pagenavigator">
                <div className="page-navigator">
                  <button className="backBtn" onClick={goBackward}>
                    &lt;
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          if (verifyOtp.isSuccess) {
            navigate("./signupdetails", { state: { phoneNum: phoneNum } });
          } else {
            setShowSuccessModal(false);
          }
        }}
        message={
          verifyOtp.isSuccess
            ? "Otp Verified Successfully"
            : "Otp Resent Successfully"
        }
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={
          verifyOtp.isError ? "Otp Verification Failed" : "Resent Otp Failed"
        }
      />
    </div>
  );
};

export default OtpScreen;
