import React from 'react'
import { dashboard_logo_kirkos_collapse } from '../../../assets/images';
import "./page-loader.scss"

const PageLoader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>
            <div style={{ position: 'relative' }}>
                <div className="custom-loader"></div>
                <img
                    src={dashboard_logo_kirkos_collapse}
                    alt="Kirkos Logo"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50px',
                        height: 'auto',
                        zIndex: 2
                    }}
                />
            </div>
        </div>
    )
}

export default PageLoader