import React, { useRef, useState } from "react";
import { CustomCheckBox } from "../../../components";
import { useForm } from "react-hook-form";

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCustomizeResult: (result: any) => void;
}

const CustomerCustomizeYourSearch: React.FC<CreateModalProps> = ({
  isOpen,
  onClose,
  onCustomizeResult,
}) => {
  const {
    control,

    formState: { isValid, errors },
    setValue,
    trigger,
    register,

    getValues,
  } = useForm({
    mode: "onChange",
  });
  const [aedValue, aedSetValue] = useState(0);
  const [mileageValue, SetMileageValue] = useState<string | number>("");

  const [selectedSeatCapacity, setSelectedSeatCapacity] = useState<number>(0);
  const [selectedCarSize, setSelectedCarSize] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  const handleChangeAED = (event: any) => {
    aedSetValue(parseFloat(event.target.value));
  };

  const handleChangeMileage = (event: any) => {
    const value = event.target.value;
    SetMileageValue(value === "" ? "" : parseFloat(value));
  };

  const handleChangeYear = (event: any) => {
    setSelectedYear(event.target.value);
  };
  const handleYearChange = (selectedYear: string) => {
    console.log("Selected Year:", selectedYear);
    setSelectedYear(selectedYear);
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(
        <option value={year} key={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const handleCheckBoxSeatChange = (fieldName: string, value: number) => {
    if (fieldName.startsWith("Seat")) {
      setSelectedSeatCapacity(selectedSeatCapacity === value ? 0 : value);
    }
  };
  const handleCheckBoxChange = (fieldName: string, value: string) => {
    if (fieldName.startsWith("CarSize")) {
      setSelectedCarSize(selectedCarSize === value ? "" : value);
    }
  };

  const handleProceedToCustomize = () => {
    const customizeResult = {
      seat_capacity: selectedSeatCapacity,
      car_type: selectedCarSize,
      amount_range: aedValue,
      mileage: mileageValue,
      model_year: selectedYear,
    };

    console.log("customize result:", customizeResult);
    onCustomizeResult(customizeResult);
    onClose();
  };

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "flex" : "none",
      }}
    >
      <div
        className="modal-dialog modal-max-w-custom-search p-2 mb-3"
        role="document"
      >
        <div className="modal-content modal-content-dash custom-modal-dashboard ">
          <div className="row p-3 ps-4">
            <div className="col-md-10">
              <div className="modal-head-cu-search text-start">
                Customize your Search
              </div>
            </div>
            <div className="col-md-2">
              <div className="cross d-flex flex-row-reverse">
                <button
                  type="button"
                  className="close  btn  rounded-circle-btn"
                  onClick={onClose}
                >
                  <span>X</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-6">
              <div className="row">
                <div className="col-md-12">Seat Capacity</div>
                <div className="col-md-12">
                  <div className="form-check">
                    <CustomCheckBox
                      isChecked={selectedSeatCapacity === 5}
                      onChange={() => handleCheckBoxSeatChange("Seat", 5)}
                    />
                    <label className="form-check-label text-start">
                      5 Seats{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <CustomCheckBox
                      isChecked={selectedSeatCapacity === 7}
                      onChange={() => handleCheckBoxSeatChange("Seat", 7)}
                    />
                    <label className="form-check-label text-start">
                      7 Seats{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <CustomCheckBox
                      isChecked={selectedSeatCapacity === 6}
                      onChange={() => handleCheckBoxSeatChange("Seat", 6)}
                    />
                    <label className="form-check-label text-start">
                      6 Seats{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <CustomCheckBox
                      isChecked={selectedSeatCapacity === 12}
                      onChange={() => handleCheckBoxSeatChange("Seat", 12)}
                    />
                    <label className="form-check-label text-start">
                      12 Seats{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="col-md-12">Car Size / Facility</div>
              <div className="col-md-12">
                <div className="form-check">
                  <CustomCheckBox
                    isChecked={selectedCarSize === "Mini"}
                    onChange={() => handleCheckBoxChange("CarSize", "Mini")}
                  />
                  <label className="form-check-label text-start">Mini</label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check">
                  <CustomCheckBox
                    isChecked={selectedCarSize === "Standard"}
                    onChange={() => handleCheckBoxChange("CarSize", "Standard")}
                  />
                  <label className="form-check-label text-start">
                    Standard
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check">
                  <CustomCheckBox
                    isChecked={selectedCarSize === "Compact"}
                    onChange={() => handleCheckBoxChange("CarSize", "Compact")}
                  />
                  <label className="form-check-label text-start">Compact</label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check">
                  <CustomCheckBox
                    isChecked={selectedCarSize === "Economy"}
                    onChange={() => handleCheckBoxChange("CarSize", "Economy")}
                  />
                  <label className="form-check-label text-start">Economy</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-2 text-start px-3">
            <div className="col-md-12">
              <label htmlFor="custom_Range" className="form-label">
                AED Range
              </label>
              <input
                type="range"
                className="form-range-progress"
                min="0"
                max="5000"
                id="custom_Range"
                value={aedValue}
                onChange={handleChangeAED}
              />
            </div>
            <div className="col-md-12">Max. AED {aedValue}</div>
          </div>

          <div className="row pt-2 text-start px-3">
            <div className="col-md-12">
              <label htmlFor="custom_Range" className="form-label">
                Daily Mileage
              </label>
              <input
                type="text"
                className="merchant-input-style-create-product"
                id="mileage"
                value={mileageValue}
                onChange={handleChangeMileage}
              />
            </div>
          </div>
          <div className="row pt-2 text-start px-3">
            <div className="col-md-12">
              <label htmlFor="custom_Range" className="form-label">
                Model Year
              </label>
              <input
                type="text"
                className="merchant-input-style-create-product"
                id="year"
                value={selectedYear}
                onChange={handleChangeYear}
              />

              {/* <CustomInput
                inputName="mileage"
                inputType="mileage"
                className="merchant-input-style-create-product"
                placeholder=""
                editable={true}
                control={control}
                onInputChange={handleChangeMileage}
              /> */}
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center pt-2 text-center">
            <button
              type="button"
              className=" btn ok-button-modal"
              onClick={handleProceedToCustomize}
            >
              Proceed to Customize
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCustomizeYourSearch;
