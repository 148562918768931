import React, { useEffect, useState } from "react";
import "../../Css/Dashboard_style.css";
import { Outlet, useLocation } from "react-router-dom";
import CustomerDashBoardNavbar from "../../../components/CustomerDashBoard/CustomerDashBoardNavbar";
import CustomerDashboardSidebar from "../../../components/CustomerDashBoard/CustomerDashBoardSidebar";

const CustomerDashboard = () => {
  const [selectedNavToggle, setSelectedNavToggle] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollContainer = document.querySelector(".dashboardright-scroll");
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <div className="col-md-12 col-xl-12 col-sm-12 container-fluid   dashboardcontainer">
      <div className="row flex-nowrap hide-overflow">
        <CustomerDashboardSidebar
          selectedNavToggle={selectedNavToggle}
          setSelectedNavToggle={setSelectedNavToggle}
        />
        <div className="col dashboardright d-flex justify-content-center ">
          <div className="dashboardright-width">
            <CustomerDashBoardNavbar
              selectedNavToggle={selectedNavToggle}
              setSelectedNavToggle={setSelectedNavToggle}
            />
            <div className="dashboardright-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
