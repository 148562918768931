import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./styles.css";
import CustomInput from "../../CustomInput";
import CustomRadio from "../../customRadio";
import "react-datepicker/dist/react-datepicker.css";
import { useRef, useState } from "react";
import { vector_vv, car_key, icons_armour } from "../../../assets/images";
import RentalSummaryCarousel from "../RentalSummaryCarousel";
import DateTimeComponent from "../../DateTimeComponent";
import { TimePicker } from "../../TimePicker";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import {
  UpdateCart,
  insuranceBookingDetails,
  CreateCart,
  InsuredUpdateCart,
} from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import InsuranceDateComponent from "../InsuranceDate";
import SuccessModal from "../../SuccessModal";
import ErrorModal from "../../ErrorModal";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";

function FinalReport() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    formData,
    deliveredBy,
    deliveryDetails,
    recollectionDetails,
    insuranceExpiryDate,
    deliveryTime,
    deliveryDate,
    selectedCompany,
    selectedGarage,
    selectedGarageId,
    extraBabySeat,
    additionalDriver,
    windShieldProtection,
    scdwInsurance,
    childSeatDaily,
    PAIDaily,
    SDWDaily,
    WTPDaily,
    paiInsurance,
    DriverDaily,
  } = location.state;

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [insuredCart, setInsuredCart] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [merchantId, setMerchantId] = useState<string>("");
  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
    if (value === "Yes") {
      navigate("/customer/myaccount");
    }
  };

  const expiryDate = insuranceExpiryDate?.insuranceDate || "";
  // const formattedExpiryDate = expiryDate
  //   ? expiryDate.toISOString().split("T")[0]
  //   : "";

  const deliveryInsuredTime = deliveryTime || "";

  const deliveryDateTime =
    new Date(deliveryDate).toISOString().split("T")[0] + "T" + "00:00";

  useEffect(() => {
    if (location.state) {
      const { deliveryDetails, formData } = location.state;
      if (deliveryDetails) {
        setValue("deliveryLocation", deliveryDetails?.deliveryLocation);
        setValue(
          "recollectionLocation",
          recollectionDetails?.recollectionLocation
        );

        setValue("insuranceCompany", formData?.insuranceCompany);
        setValue("InsurancePolicy", formData?.insurancePolicy);
        setValue("insuranceClaim", formData?.insuranceClaim);
        setValue("plateNumber", formData?.plateNumber);
        setValue("chassisNumber", formData?.chassisNumber);
        setValue("garageName", selectedGarageValue);
      }
    }
  }, [location.state, setValue]);

  const handleSubmit = () => {
    setLoading(true);
    const formattedDeliveryDate = moment(deliveryDate).format("YYYY-MM-DD");
    const formattedInsurance = moment(expiryDate).format("YYYY-MM-DD");
    const formattedDeliveryTime = deliveryTime;
    console.log(
      "formattt--------------------------expdate",
      formattedInsurance
    );

    const otherGarageName = formData?.otherGarage;
    const selectedGarageValue =
      formData?.garageCompany === "Other Garages"
        ? otherGarageName
        : selectedGarage;
    console.log("selectedGarageValue", selectedGarageValue);
    const otherGarageId = "Other";
    const selectedGarageIdValue =
      selectedGarageId === -1 ? otherGarageId : selectedGarageId;
    console.log("selectedGarageIdValue", selectedGarageIdValue);

    const userData = {
      insurance_company: selectedCompany,
      insurance_policy: formData?.insurancePolicy,
      insurance_claim: formData?.insuranceClaim,
      insurance_expiry_date: formattedInsurance,
      plate_number: formData?.plateNumber,
      chassis_number: formData?.chassisNumber,
      garage_name: selectedGarageValue,
      garage_id: selectedGarageIdValue,

      delivery_date: `${formattedDeliveryDate}T00:00:00`,
      delivery_address: {
        type: "delivery_location",
        address: deliveryDetails?.deliveryLocation,
        street: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: deliveryDetails?.latitude,
        longitude: deliveryDetails?.longitude,
        landMark: "",
      },
      recollection_location: {
        type: "RECOLLECTION_LOCATION",
        address: recollectionDetails?.recollectionLocation,
        street: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: recollectionDetails?.latitude,
        longitude: recollectionDetails?.longitude,
        landMark: "",
      },
    };
    handleAddCart.mutate(userData);
  };

  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const customerId =
    decodedToken && decodedToken["customer-id"]
      ? decodedToken["customer-id"]
      : null;

  const handleAddCart = useMutation({
    mutationFn: (updateData: insuranceBookingDetails) =>
      axiosInstance.post(
        `${API_URL.INSURANCE_CART}/${customerId}/carts/insuredCustomer`,
        updateData
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      const insuredCarts = successResponse?.data;
      const merchantIds = Object.keys(successResponse.data)[0] || "";

      setMerchantId(merchantIds);
      setInsuredCart(insuredCarts[merchantIds]);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (merchantId !== "" && insuredCart !== null) {
      console.log("Calling handleUpdate.mutate(updateData)");
      handleUpdate.mutate(updateData);
    }
  }, [merchantId, insuredCart]);

  const handleUpdate = useMutation({
    mutationFn: (updateData: UpdateCart) =>
      axiosInstance.put(`${API_URL.UPDATE}/${customerId}/carts`, updateData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      handleCreate.mutate(CreateCart);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  const otherGarageName = formData?.otherGarage;
  const selectedGarageValue =
    formData?.garageCompany === "Other Garages"
      ? otherGarageName
      : selectedGarage;
  const otherGarageId = "OTHER";
  const selectedGarageIdValue =
    selectedGarageId === -1 ? otherGarageId : selectedGarageId;
  console.log("selectedGarageIdValue", selectedGarageIdValue);
  const updateData: InsuredUpdateCart = {
    [merchantId]: {
      applied_discount_code: null,
      shop_information: {
        shop_address: {
          pinCode: insuredCart?.shop_information?.shop_address?.pinCode || "",
          businessName:
            insuredCart?.shop_information?.shop_address?.businessName || "",
          address: insuredCart?.shop_information?.shop_address?.address || "",
          landmark: insuredCart?.shop_information?.shop_address?.landmark || "",
          city: insuredCart?.shop_information?.shop_address?.city || "",
          district: insuredCart?.shop_information?.shop_address?.district || "",
          state: insuredCart?.shop_information?.shop_address?.state || "",
          country: insuredCart?.shop_information?.shop_address?.country || "",
          longitude:
            insuredCart?.shop_information?.shop_address?.longitude || "",
          latitude: insuredCart?.shop_information?.shop_address?.latitude || "",
          streetOrArea:
            insuredCart?.shop_information?.shop_address?.streetOrArea || "",
          ward: insuredCart?.shop_information?.shop_address?.ward || "",
          number_and_streetname:
            insuredCart?.shop_information?.shop_address
              ?.number_and_streetname || "",
          estimated_delivery_time:
            insuredCart?.shop_information?.shop_address
              ?.estimated_delivery_time || "",
          estimated_delivery_fee:
            insuredCart?.shop_information?.shop_address
              ?.estimated_delivery_fee || "",
          shop_entity:
            insuredCart?.shop_information?.shop_address?.shop_entity || "",
        },
        shop_name: insuredCart?.shop_information?.shop_name || "",
        shop_id: insuredCart?.shop_information?.shop_id || "",
        image: "",
      },
      delivery_location: {
        type: "delivery_location",
        address: deliveryDetails?.deliveryLocation,
        street: "",
        city: " ",
        district: "",
        state: insuredCart?.delivery_location?.state,
        country: insuredCart?.delivery_location?.country,
        pinCode: "",
        latitude: deliveryDetails?.latitude,
        longitude: deliveryDetails?.longitude,
        landMark: "",
      },
      recollection_location: {
        type: "",
        address: recollectionDetails?.recollectionLocation,
        street: "",
        city: " ",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: recollectionDetails?.latitude,
        longitude: recollectionDetails?.longitude,
        landMark: "",
      },
      note: "",
      matrix_user_id: "",
      delivery_date: "",
      recollection_date: "",
      order_total_amount: 0,
      delivered_by: deliveredBy,
      delivery_preferred_time: deliveryTime,
      rental_days: 2,
      products: [
        {
          price: insuredCart?.products[0]?.price,
          toppings: [],
          product_id: insuredCart?.products[0]?.product_id,
          base_product_id: insuredCart?.products[0]?.base_product_id,
          product_name: insuredCart?.products[0]?.product_name,
          product_description: "",
          gst: insuredCart?.products[0]?.gst,
          baby_seat: extraBabySeat,
          baby_seat_amount:
            extraBabySeat === true ? childSeatDaily?.insuranceRate : 0,
          price_per_day: 0,
          pai_insurance_amount:
            paiInsurance === true ? PAIDaily?.insuranceRate : 0,
          wind_shield_protection:
            windShieldProtection === true ? WTPDaily?.insuranceRate : 0,
          cdw_flag: false,
          scdw_flag: scdwInsurance,
          scdw_amount: scdwInsurance === true ? SDWDaily?.insuranceRate : 0,
          cdw_amount: 0,
          additional_driver: additionalDriver,
          additional_driver_amount:
            additionalDriver === true ? DriverDaily?.amount : 0,
          merchant_id: insuredCart?.products[0]?.merchant_id,
          quantity: insuredCart?.products[0]?.quantity,
          item_id: insuredCart?.products[0]?.item_id,
          buy_more_pay_less: null,
          discount_event: null,
          variant: {},
          shop_id: insuredCart?.products[0]?.shop_id,
        },
      ],
      delivery_address: {
        type: "DELIVERY_ADDRESS",
        addressType: "",
        firstName: "",
        lastName: "",
        address: "",
        street: "",
        city: " ",
        district: " ",
        state: "",
        country: "",
        pinCode: "",
        mobileNumber: "",
        latitude: "",
        longitude: "",
        landMark: "",
        email: "",
      },
      insurance: {
        insurance_company: insuredCart?.insurance?.insurance_company,
        insurance_policy: insuredCart?.insurance?.insurance_policy,
        insurance_claim: insuredCart?.insurance?.insurance_claim,
        insurance_expiry_date: insuredCart?.insurance?.insurance_expiry_date,
        plate_number: insuredCart?.insurance?.plate_number,
        chassis_number: insuredCart?.insurance?.chassis_number,
        garage_name: selectedGarageValue,
        garage_id: selectedGarageIdValue,

        delivery_address: {
          type: "delivery_location",
          address: deliveryDetails?.deliveryLocation,
          street: "",
          city: "",
          district: "",
          state: "",
          country: "",
          pinCode: "",
          latitude: deliveryDetails?.latitude,
          longitude: deliveryDetails?.longitude,
          landMark: "",
        },
        recollection_location: {
          type: "RECOLLECTION_LOCATION",
          address: recollectionDetails?.recollectionLocation,
          street: "",
          city: "",
          district: "",
          state: "",
          country: "",
          pinCode: "",
          latitude: recollectionDetails?.latitude,
          longitude: recollectionDetails?.longitude,
          landMark: "",
        },

        delivery_date: insuredCart?.insurance?.delivery_date,
      },
      insurance_cart: insuredCart?.insurance_cart,
    },
  };

  const CreateCart = {
    merchant_id: merchantId,
    payment_method: "COD",
  };

  const handleCreate = useMutation({
    mutationFn: (createData: CreateCart) =>
      axiosInstance.post(API_URL.CREATE_CART, createData, {
        headers: {
          location: "0,0",
          language: "english",
          // "unomi-session-id": "122333",
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponsecart", successResponse.data.order_number);
      setOrderNumber(successResponse.data.order_number);
      handleClear.mutate();
      setShowSuccessModal(true);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleClear = useMutation({
    mutationFn: () =>
      axiosInstance.get(
        `${API_URL.CLEAR_CART}/${customerId}/carts/${merchantId}/clear`
      ),

    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const refpopup = useRef(null);

  const popoverHoverFocus = (
    <Popover
      id="popover-trigger-hover-focus"
      title="Popover bottom"
      className="popover-custom-report"
    >
      <div className="content-popover">
        <ExclamationCircle className="popover-icon-custom" />
        <div className="dashboard-poppins-font-style px-2 align-content-center">
          Continue with out Upgrade
        </div>
      </div>
    </Popover>
  );
  return (
    <>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-7 page-max-w  mb-2" ref={refpopup}>
            <div className="base-content-booking base-booking-content-dash custom-Booking-base-dashboard ">
              <div className="p-2">
                <div className="row pt-3 text-left basebottom-div mb-3 ">
                  <div className="col-12 d-flex ">
                    <img
                      src={vector_vv}
                      alt="file_browse_icon"
                      className="img-fluid header-icon"
                    />
                    <span className="col-md-11 page-head-add ps-2">
                      Insurance Details
                    </span>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-md-12  text-left page-left">
                    <div className="row">
                      <div className="col-md-4 pt-2 dashboard-poppins-font-style">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Company
                        </label>
                        <CustomInput
                          inputName="insuranceCompany"
                          inputType="text"
                          className="merchant-input-Style-insurance"
                          placeholder="Suk"
                          editable={false}
                          onInputChange={(value: string) => {}}
                          control={control}
                        />
                      </div>

                      <div className="col-md-4 mt-2">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Policy #{" "}
                          <span className="merchantStar">*</span>
                        </label>
                        <CustomInput
                          inputName="InsurancePolicy"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder="Eg: 56335358689"
                          editable={false}
                          onInputChange={(value: string) => {}}
                          control={control}
                        />
                        <div className="row"></div>
                      </div>
                      <div className="col-md-4 mt-2 ">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Claim #
                          <span className="merchantStar">*</span>
                        </label>
                        <CustomInput
                          inputName="insuranceClaim"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder="drryerfgj5464"
                          editable={false}
                          onInputChange={(value: string) => {}}
                          control={control}
                        />
                      </div>
                      <div className="col-md-4 mt-4">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Expiry Date{" "}
                          <span className="merchantStar">*</span>
                        </label>
                        <InsuranceDateComponent
                          pickerType="date"
                          placeholder="yyyy-mm-dd"
                          value={insuranceExpiryDate?.insuranceDate}
                          onChange={(value: string) => {}}
                          disabled
                        />
                      </div>
                      {formData?.plateNumber && (
                        <div className="col-md-4 mt-4">
                          <label className="form-label dashboard-poppins-font-style">
                            Plate Number
                          </label>
                          <CustomInput
                            inputName="plateNumber"
                            inputType="text"
                            className="merchant-input-Style-line"
                            placeholder=""
                            editable={false}
                            onInputChange={(value: string) => {}}
                            control={control}
                          />
                        </div>
                      )}
                      {formData?.chassisNumber && (
                        <div className="col-md-4 mt-4">
                          <label className="form-label dashboard-poppins-font-style">
                            Chassis Number
                          </label>
                          <CustomInput
                            inputName="chassisNumber"
                            inputType="text"
                            className="merchant-input-Style-line"
                            placeholder="drryerfgj5464"
                            editable={false}
                            onInputChange={(value: string) => {}}
                            control={control}
                          />
                        </div>
                      )}
                      {formData?.garageCompany && (
                        <div className="col-md-4 mt-4">
                          <label className="form-label dashboard-poppins-font-style">
                            Garage
                          </label>
                          <CustomInput
                            inputName="garageName"
                            inputType="text"
                            className="merchant-input-Style-line"
                            placeholder=""
                            editable={false}
                            onInputChange={(value: string) => {}}
                            control={control}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 d-flex">
                        <img
                          src={car_key}
                          alt="file_browse_icon"
                          className="img-fluid header-icon"
                        />
                        <span className="col-md-11 page-head-add ps-2">
                          Confirm Car Delivery Location, Date, Time
                        </span>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-md-4 pt-3">
                        <div className="div-border-loc">
                          <label className="label_insurance">
                            Delivery Location
                          </label>

                          <CustomInput
                            inputName="deliveryLocation"
                            inputType="text"
                            className="custom-bottom-line-vheins"
                            placeholder="E-20, Dera, Dubai"
                            editable={false}
                            onInputChange={(value: string) => {}}
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 pt-3">
                        <div className="div-border-loc">
                          <label className="label_insurance">
                            Recollection Location
                          </label>

                          <CustomInput
                            inputName="recollectionLocation"
                            inputType="text"
                            className="custom-bottom-line-vheins"
                            placeholder="E-20, Dera, Dubai"
                            editable={false}
                            onInputChange={(value: string) => {}}
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 pt-3">
                        <DateTimeComponent
                          pickerType="date"
                          placeholder="Delivery Date"
                          label="Delivery Date"
                          value={deliveryDate}
                          onChange={(value: string) => {}}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 pt-3">
                        <TimePicker
                          label="Delivery Time"
                          value={deliveryTime}
                          onChange={(value: string) => {}}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pt-5 text-left basebottom-div">
                  <div className="col-12 d-flex ">
                    <img
                      src={car_key}
                      alt="file_browse_icon"
                      className="img-fluid header-icon  ml-2"
                    />
                    <span className="col-md-11 page-head-add ps-2">
                      Cars Available for Insurance
                    </span>
                  </div>
                  <div className="col-md-12 text-start mt-1 ">
                    <div className="bottom-content-font bottom-content p-3">
                      Vehicle provided will be based on your insurance approval
                      and car availability
                    </div>
                  </div>
                </div>

                <div className="row pt-5 text-left basebottom-div">
                  <div className="col-12 d-flex  ">
                    <img
                      src={icons_armour}
                      alt="file_browse_icon"
                      className="img-fluid header-icon  ml-2"
                    />
                    <span className="col-md-11 page-head-add ps-2 pb-2">
                      Would you like to upgrade the car category (Self pay)
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 pt-1">
                    <div className="d-flex">
                      <label className="ps-5">YES</label>
                      <div className="ps-2">
                        <CustomRadio
                          onChange={() => handleRadioChange("Yes")}
                          checked={selectedOption === "Yes"}
                          label=""
                        />
                      </div>
                      <label className="ps-3">NO</label>
                      <div className="ps-2">
                        <CustomRadio
                          onChange={() => handleRadioChange("No")}
                          checked={selectedOption === "No"}
                          label=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end pt-4 pb-2">
                <OverlayTrigger
                  container={refpopup}
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={popoverHoverFocus}
                >
                  <button
                    className=" btn all_button_style text-black"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    <div className="">CONFIRM & CONTINUE</div>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <RentalSummaryCarousel />
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/customer/myaccount");
        }}
        message={`<span className="modal-span-custom" style="font-size: 18px;color: var(--font-color-theme-1);font-weight: bold;font-family: Poppins, sans-serif;">${orderNumber}</span><br /><div style="font-family: Poppins, sans-serif;">Your order has been placed successfully. You will receive a notification with confirmation and car delivery date and time</div>`}
      />

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </>
  );
}

export default FinalReport;
