import React, { useRef, useState } from "react";
import "./CreateProduct.css";
import {
  kirkos_merchant_red_car,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
  kirkos_merchant_profile,
  kirkos_logo,
  file_browse_icon,
  calender_icon,
  thumb_up,
  vector,
} from "../../../assets/images";
import { CustomInput } from "../../../components";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { ToastContainer, toast } from "react-toastify";

interface CreateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProduct: React.FC<CreateModalProps> = ({ isOpen, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFileInput = () => {
    fileInputRef?.current?.click();
  };

  //-----------------------
  const [isSuccess, setIsSuccess] = useState<null | boolean>(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadResponse, setUploadResponse] = useState<Record<
    string,
    any
  > | null>(null);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let isSuccess = true;
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    const allowedExtensions = ["xlsx", "xls"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || !allowedExtensions.includes(extension)) {
      toast.error("Please upload a valid Excel file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    setIsSuccess(null);
    try {
      const response = await axiosInstance.post(
        API_URL.PRODUCT_BULKUPLOAD,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Bulk Upload Response", response?.data);
      setUploadResponse(response?.data);
      setIsSuccess(true);
      setUploadMessage("Documents Uploaded Successfully!");
    } catch (error) {
      console.error("Error uploading image", error);
      setIsSuccess(false);
      setUploadMessage("Error uploading documents. Please try again.");
    }
  };

  const handleModalClose = () => {
    setIsSuccess(null);
    onClose();
    setUploadMessage("");
  };

  //-----------------------

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      style={{
        display: isOpen ? "flex" : "none",
      }}
    >
      <div className="modal-dialog modal-max-w p-2 mb-3" role="document">
        <div className="modal-content modal-content-dash custom-modal-dashboard ">
          <div className="p-3 ps-4">
            <div className="d-flex text-end">
              <h4
                className="modal-head-add text-start"
                style={{ width: "100%" }}
              >
                Add Product
              </h4>

              <div className="text-end">
                <button
                  type="button"
                  className="close  btn  rounded-circle-btn"
                  //onClick={onClose}
                  onClick={handleModalClose}
                >
                  <span>X</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pt-3 text-right modalright">
                <div className="row pt-2">
                  <div className="col-md-12 pt-3 text-center">
                    {isSuccess === true ? (
                      <div className="p-3 success-message mb-4 dashboard-poppins-font-style">
                        <div>
                          <img
                            src={thumb_up}
                            width="90"
                            height="90"
                            alt="Success Icon"
                            className="thumb"
                          />
                        </div>
                        {uploadMessage}
                        {/* <p>
                          <strong>Created Products Count:</strong>{" "}
                          {uploadResponse?.created_products_count || 0}
                        </p> */}
                        {/* <p>
                          <strong>Duplicate Products Count:</strong>{" "}
                          {uploadResponse?.existing_products_count || 0}
                        </p> */}
                      </div>
                    ) : isSuccess === false ? (
                      <div className="p-3 error-message mb-4 dashboard-poppins-font-style">
                        <div>
                          <img
                            src={vector}
                            width="90"
                            height="90"
                            alt="Error Logo"
                            className="thumb"
                          />
                        </div>
                        {uploadMessage}
                      </div>
                    ) : (
                      <div className="p-3 dashed-box border-dashed drag-dash-box-modal">
                        <div className="dashed-pattern text-center">
                          <img
                            src={file_browse_icon}
                            alt="file_browse_icon"
                            className="img-fluid mb-2"
                          />
                          <p className="text-light mb-2 dashboard-poppins-font-style">
                            Select & Upload Excel Sheets
                            <span className="merchantStar">*</span>
                          </p>
                          <button
                            className="btn btn-custom"
                            onClick={openFileInput}
                          >
                            Browse Excel
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            accept=".xlsx, .xls"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 modal-footer modal-footer-bg  d-flex justify-content-right  text-right">
            <div className="col-md-3">
              <button
                type="button"
                className=" btn ok-button-modal"
                disabled={!isValid}
              >
                SUBMIT
              </button>
            </div>
          </div> */}
        </div>
      </div>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default CreateProduct;
