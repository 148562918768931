// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* -----------------------------------------------------------------  */

.floating-input-style {
  border: none;
  background: transparent;
  outline: none;
  border-radius: 0%;
  --bs-input-active-bg: transparent;
  --bs-input-active-border-color: transparent;
  color: var(--font-color-primary);
  --bs-body-color: #ffffff;
  /* --bs-body-color-rgb: 33, 37, 41; */
  --bs-body-bg: #ffffff00;
  /* --bs-body-bg-rgb: 255, 255, 255; */
  border-bottom: 2px solid var(--border-color-theme-7);
}
.floating-input-style:hover {
  background-color: transparent;
  border: none;
  outline: none;
}
.floating-input-style:focus {
  box-shadow: none;
  outline: none;
}

.form-floating-label {
  background-color: transparent;
  color: var(--font-color-primary);
  --bs-body-color: #ffffff;
  --bs-body-bg: #ffffff00;
  --bs-body-color-rgb: 255, 255, 255;
}
.form-floating-label:hover {
  background-color: transparent;
}

/* ------------------------------------------------------------------ */
`, "",{"version":3,"sources":["webpack://./src/components/FloatingInputLocation/styles.css"],"names":[],"mappings":"AAAA,uEAAuE;;AAEvE;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,iCAAiC;EACjC,2CAA2C;EAC3C,gCAAgC;EAChC,wBAAwB;EACxB,qCAAqC;EACrC,uBAAuB;EACvB,qCAAqC;EACrC,oDAAoD;AACtD;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,wBAAwB;EACxB,uBAAuB;EACvB,kCAAkC;AACpC;AACA;EACE,6BAA6B;AAC/B;;AAEA,uEAAuE","sourcesContent":["/* -----------------------------------------------------------------  */\n\n.floating-input-style {\n  border: none;\n  background: transparent;\n  outline: none;\n  border-radius: 0%;\n  --bs-input-active-bg: transparent;\n  --bs-input-active-border-color: transparent;\n  color: var(--font-color-primary);\n  --bs-body-color: #ffffff;\n  /* --bs-body-color-rgb: 33, 37, 41; */\n  --bs-body-bg: #ffffff00;\n  /* --bs-body-bg-rgb: 255, 255, 255; */\n  border-bottom: 2px solid var(--border-color-theme-7);\n}\n.floating-input-style:hover {\n  background-color: transparent;\n  border: none;\n  outline: none;\n}\n.floating-input-style:focus {\n  box-shadow: none;\n  outline: none;\n}\n\n.form-floating-label {\n  background-color: transparent;\n  color: var(--font-color-primary);\n  --bs-body-color: #ffffff;\n  --bs-body-bg: #ffffff00;\n  --bs-body-color-rgb: 255, 255, 255;\n}\n.form-floating-label:hover {\n  background-color: transparent;\n}\n\n/* ------------------------------------------------------------------ */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
