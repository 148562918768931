import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  Pagination,
  Row,
} from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse, Invoice } from "../../../types";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";

interface LocationData {
  latitude: string;
  longitude: string;
}

const ApprovedOrders = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const roles =
    decodedToken && decodedToken["roles"] ? decodedToken["roles"] : null;
  const showBranchColumn = roles?.includes("ROLE_MERCHANT_OPERATION");

  const {
    control,
    formState: { isValid },
    setValue,
  } = useForm({ mode: "onChange" });

  const [currentPage, setCurrentPage] = useState(0);
  const [currentExportPage, setCurrentExportPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchResults, setSearchResults] = useState<Invoice[] | null>(null);
  const [showExportDateField, setShowExportDateField] = useState(false);
  const [exportDate, setExportDate] = useState("");
  const [showList, setShowList] = useState(false);
  const [exportList, setExportList] = useState<ApiResponse | null>(null);
  const [dateChange, setDateChange] = useState(false);
  const [searchType, setSearchType] = useState("date");
  const [searchValue, setSearchValue] = useState("");

  const fetchApprovedOrders = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.PURCHASE_ORDER}/${merchantId}?status=MERCHANT_ORDER_ACCEPT&page=${page}`
    );
    return response?.data;
  };

  const { data: approvedOrders } = useQuery<ApiResponse>({
    queryKey: ["approvedOrders", currentPage],
    queryFn: () => fetchApprovedOrders(currentPage),
    refetchInterval: 30000,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchApprovedOrders(page);
  };

  const handlepageExportChange = (page: number) => {
    setCurrentExportPage(page);
    handleList(page);
  };

  //======================================================================================

  const [locationSearchQuery, setLocationSearchQuery] = useState<string>("");
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: "0",
    longitude: "0",
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");

  const fetchLocationName = async (locationSearchQuery: string) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${locationSearchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  // useQuery<string>({
  //   queryKey: ["locationName", locationSearchQuery],
  //   queryFn: () => fetchLocationName(locationSearchQuery),
  // });

  useQuery<string>({
    queryKey: ["locationName", locationSearchQuery],
    queryFn: () => fetchLocationName(locationSearchQuery),
    enabled: !isLocationSelected,
  });

  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https:///nominatim.kirkos.ae/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat).toString(),
        longitude: parseFloat(lon).toString(),
      });
    }

    setSelectedSuggestion(selectedSuggestion);
    setLocationSearchQuery(selectedSuggestion);

    setValue("location", selectedSuggestion);
    setIsLocationSelected(true);
    setAddressSuggestions([]);
  };

  const handleLocationSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocationSearchQuery(event.target.value);
    setIsLocationSelected(false);
  };
  //===============================================================================================

  const handleExport = () => {
    setShowExportDateField(true);
  };

  //----------------------------------------------

  const handleList = async (page: number) => {
    const payload = {
      status: "MERCHANT_ORDER_ACCEPT",
      order_type: "DELIVERED",
      ...(searchType === "date" && { date: exportDate }),
      ...(searchType === "phone_number" && { phone_number: searchValue }),
      ...(searchType === "location" && {
        location: {
          latitude: selectedLocation.latitude,
          longitude: selectedLocation.longitude,
        },
      }),
    };

    try {
      const response = await axiosInstance.post(
        `${API_URL.EXPORTLIST_SEARCH_RESULTS}?page=${page}`,
        payload
      );
      setExportList(response?.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    // setExportDate("");
  };

  //-----------------------------------------------

  const handleExportSubmit = () => {
    if (exportList && exportList?.content?.length > 0) {
      const fileName = `ApprovedOrders_${exportDate}.xlsx`;

      const header = [
        "#",
        "Order Number",
        "Number of Days",
        "Customer Name",
        "Phone Number",
        "Product Name",
        "Location",
        "Date",
      ];

      const exportData = exportList?.content?.map((invoice, index) => [
        index + 1,
        invoice?.orderId ?? "",
        invoice?.rental_days ?? "",
        invoice?.customer_name ?? "",
        invoice?.customer_phone_number ?? "",
        invoice?.products?.[0]?.productName ?? "",
        invoice?.delivery_location?.address ?? "",
        formatDate(invoice?.createdAt) ?? "",
      ]);

      const worksheet = XLSX.utils.aoa_to_sheet([header, ...exportData]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Order List");

      XLSX.writeFile(workbook, fileName);
    }
    setExportDate("");
    setShowList(false);
  };

  const handleSearch = async () => {
    let date_filter_key = "";
    if (searchCriteria === "order_date") {
      date_filter_key = searchCriteria.toLowerCase().replace(" ", "_");
    }

    const payload = {
      order_number: searchCriteria === "order_number" ? searchQuery : "",
      date_filter_key: date_filter_key,
      date: searchCriteria === "order_date" ? searchQuery : "",
      insurance_claim: searchCriteria === "insurance_claim" ? searchQuery : "",
    };

    try {
      const response = await axiosInstance.post(
        API_URL.SEARCH_RESULTS,
        payload
      );
      setSearchResults(response?.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    setSearchQuery("");
  };

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const dateFormatting = (date: Date | null) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setExportDate(formattedDate);
      setDateChange(true);
    } else {
      setExportDate("");
    }
  };

  const ordersToDisplay = searchResults || approvedOrders?.content;

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedToday = `${year}-${month}-${day}`;
    setExportDate(formattedToday);
  }, []);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      (searchType === "date" && exportDate) ||
      (searchType === "location" && locationSearchQuery.trim()) ||
      (searchType === "phone_number" && searchValue.trim())
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [exportDate, locationSearchQuery, searchValue, searchType]);

  // useEffect(() => {
  //   handleList();
  // }, [showExportDateField]);

  // useEffect(() => {
  //   if (dateChange) {
  //     handleList();
  //     seDateChange(false);
  //   }
  // }, [dateChange]);

  return (
    <div className="row">
      <div className="col-md-12 mb-3 mt-2">
        {!showExportDateField && (
          <Form.Group as={Row} className="align-items-center">
            <Col sm="3">
              <Form.Select
                className="custom-select"
                aria-label="Select search criteria"
                value={searchCriteria}
                onChange={(e) => setSearchCriteria(e.target.value)}
              >
                <option value="">Search by</option>
                <option value="order_number">Order Number</option>
                <option value="order_date">Date</option>
                <option value="insurance_claim">Insurance Claim</option>
              </Form.Select>
            </Col>
            {searchCriteria && (
              <>
                <Col sm="5">
                  <FormControl
                    className="search-bar"
                    aria-label="Text input with select"
                    placeholder={
                      searchCriteria === "order_date"
                        ? "DD-MM-YYYY"
                        : "Enter your Search"
                    }
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Col>
                <Col sm="2">
                  <Button className="search-button" onClick={handleSearch}>
                    Search
                  </Button>
                </Col>
              </>
            )}
          </Form.Group>
        )}
        {!showExportDateField && !searchCriteria && (
          <Row className="d-flex justify-content-end">
            <Col sm="2">
              <Button
                variant="primary"
                className="btn all_button_style text-black my-1"
                onClick={handleExport}
              >
                Export
              </Button>
            </Col>
          </Row>
        )}
        {showExportDateField && (
          <Row className="mt-3">
            <Col sm="3">
              <Form.Select
                className="custom-select"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value="date">Date</option>
                <option value="phone_number">Mobile Number</option>
                <option value="location">Location</option>
              </Form.Select>
            </Col>
            <Col sm="3">
              {searchType === "date" ? (
                <DatePicker
                  className="search-bar-export"
                  selected={
                    exportDate && /^\d{4}-\d{2}-\d{2}$/.test(exportDate)
                      ? new Date(exportDate)
                      : null
                  }
                  onChange={(date: Date | null) => dateFormatting(date)}
                  dateFormat="dd-MM-yyyy"
                  customInput={
                    <input
                      readOnly
                      className="input-date"
                      value={
                        exportDate
                          ? exportDate.split("-").reverse().join("-")
                          : ""
                      }
                    />
                  }
                  popperClassName="hide-calendar"
                  showPopperArrow={true}
                  autoComplete="off"
                  minDate={new Date()}
                />
              ) : searchType === "location" ? (
                <div style={{ position: "relative" }}>
                  <FormControl
                    className="search-bar"
                    type="text"
                    placeholder="Enter Location"
                    value={locationSearchQuery}
                    onChange={handleLocationSearchChange}
                    //onChange={(e) => setLocationSearchQuery(e.target.value)}
                  />

                  {addressSuggestions.length > 0 && (
                    <div className="absolute-div">
                      <ul className="text-start" style={{ color: "white" }}>
                        {addressSuggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              handleSuggestionClick(suggestion);
                            }}
                            // onClick={() => {
                            //   setLocationSearchQuery(suggestion);
                            //   setAddressSuggestions([]);
                            // }}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <FormControl
                  className="search-bar"
                  type="text"
                  placeholder="Enter Mobile Number"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  maxLength={9}
                />
              )}
            </Col>
            <Col sm="2">
              <Button
                className="btn all_button_style text-black my-1"
                onClick={() => handleList(currentPage)}
                disabled={isButtonDisabled}
              >
                Search List
              </Button>
            </Col>
            {exportList && (
              <Col sm="2">
                <Button
                  className="btn all_button_style text-black my-1"
                  onClick={handleExportSubmit}
                  disabled={exportList?.content?.length === 0}
                >
                  Export
                </Button>
              </Col>
            )}

            <Col sm="2">
              <Button
                className="btn all_button_style text-black my-1"
                variant="danger"
                onClick={() => {
                  setShowExportDateField(false);
                  setExportDate("");
                  setLocationSearchQuery("");
                  setSearchValue("");
                  setShowList(false);
                  setExportList(null);
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        )}
      </div>

      <div className="col-md-12 dashboard-table-div">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              {showBranchColumn && <th>Branch</th>}
              <th>Number of Days</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Product Name</th>
              <th>Location</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {exportList
              ? exportList?.content?.map((invoice: Invoice, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice?.orderId}</td>
                    {showBranchColumn && <td>{invoice?.merchantName}</td>}

                    <td>{invoice?.rental_days}</td>
                    <td>{invoice?.customer_name}</td>
                    <td>{invoice?.customer_phone_number}</td>
                    <td>{invoice?.products?.[0]?.productName}</td>
                    <td>{invoice?.delivery_location?.address}</td>
                    <td>
                      <span className="invoice-date-td">
                        {formatDate(invoice?.createdAt)}
                      </span>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="Dropdown-table"
                        >
                          <img
                            src={dashboard_vertical_three_dots}
                            width="15"
                            height="15"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="Dropdown-table-item">
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/details?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-top Dropdown-table-item-link"
                          >
                            View Details
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/assign?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-item-link"
                          >
                            Assign
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/edit?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-bottom Dropdown-table-item-link"
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              : ordersToDisplay?.map((invoice: Invoice, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice?.orderId}</td>
                    {showBranchColumn && <td>{invoice?.merchantName}</td>}
                    <td>{invoice?.rental_days}</td>
                    <td>{invoice?.customer_name}</td>
                    <td>{invoice?.customer_phone_number}</td>
                    <td>{invoice?.products?.[0]?.productName}</td>
                    <td>{invoice?.delivery_location?.address}</td>
                    <td>
                      <span className="invoice-date-td">
                        {formatDate(invoice?.createdAt)}
                      </span>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="Dropdown-table"
                        >
                          <img
                            src={dashboard_vertical_three_dots}
                            width="15"
                            height="15"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="Dropdown-table-item">
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/details?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-top Dropdown-table-item-link"
                          >
                            View Details
                          </Dropdown.Item>
                          {!showBranchColumn && (
                            <Dropdown.Item
                              as={Link}
                              to={`/merchant/accepted/assign?OrderId=${invoice.orderId}`}
                              className="Dropdown-table-list Dropdown-table-item-link"
                            >
                              Assign
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/edit?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-bottom Dropdown-table-item-link"
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        {approvedOrders && !exportList && (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {Array.from({ length: approvedOrders.totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === currentPage}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === approvedOrders.totalPages - 1}
            />
          </Pagination>
        )}

        {exportList && (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlepageExportChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {Array.from({ length: exportList.totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === currentPage}
                onClick={() => handlepageExportChange(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlepageExportChange(currentPage + 1)}
              disabled={currentPage === exportList.totalPages - 1}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default ApprovedOrders;
