import React, { ChangeEvent, useEffect, useState } from "react";
import "./Processpayment.css";
import { Copy } from "react-bootstrap-icons";
import { FieldValues, useForm } from "react-hook-form";
import CustomInput from "../CustomInput";
import rules from "../../rules";
import {
  address_delivery_icon,
  registered_contact_logo,
} from "../../assets/images";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Country,
  CountryState,
  CreateCart,
  CustomerDetails,
  DeliveryAddress,
  UpdateCart,
} from "../../types";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import RentalSummary from "../CustomerDashBoard/RentalSummary";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../SuccessModal";
import ErrorModal from "../ErrorModal";
import RentalSummarySecondPage from "../CustomerDashBoard/RentalSummarySecondPage";
import { useDispatch, useSelector } from "react-redux";
import { clearBookingValues } from "../../Redux/BookingData/bookingDataSlice";
import moment from "moment";

const ProcessPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bookingValues } = useSelector((state: any) => state.bookingValues);
  console.log("booking values in payment", bookingValues);

  const {
    deliveryDetails,
    optionalData,
    deliveryData,
    selectedItem,
    merchantId,
    deliveryFee,
  } = location.state;

  console.log(merchantId);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState<string>("");
  const [state1, setState1] = useState<string>("");
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [registeredInfo, setRegisteredInfo] = useState<CustomerDetails | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [stateData, setStateData] = useState<CountryState[]>([]);
  const [orderNumber, setOrderNumber] = useState<string>("");

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm({ mode: "onChange" });

  const handleSubmit = () => {
    let {
      firstname: firstName,
      lastname: lastName,
      emailaddress: email,
      contactAddress: address,
      phonenumber: mobileNumber,
    }: FieldValues = getValues();

    const userData = {
      addressType: "",
      street: "",
      city: "",
      district: "",
      pinCode: "",
      mobileNumber,
      latitude: selectedItem?.merchant_latitude,
      longitude: selectedItem?.merchant_longitude,
      landMark: "",
      firstName,
      lastName,
      email,
      address: "",
      country,
      state: state1,
    };

    handleRegistration.mutate(userData);
    dispatch(clearBookingValues());
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: DeliveryAddress) =>
      axiosInstance.post(API_URL.DELIVERY_ADDRESS, userData),

    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      handleUpdate.mutate(updateData);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
      }
    },
  });

  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  //const customerId = decodedToken["customer-id"];
  const customerId =
    decodedToken && decodedToken["customer-id"]
      ? decodedToken["customer-id"]
      : null;

  const handleUpdate = useMutation({
    mutationFn: (updateData: UpdateCart) =>
      axiosInstance.put(`${API_URL.UPDATE}/${customerId}/carts`, updateData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      handleCreate.mutate(CreateCart);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const CreateCart = {
    merchant_id: selectedItem?.merchant_id,
    payment_method: "COD",
  };

  const handleCreate = useMutation({
    mutationFn: (createData: CreateCart) =>
      axiosInstance.post(API_URL.CREATE_CART, createData, {
        headers: {
          location: "0,0",
          language: "english",
          // "unomi-session-id": "122333",
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponsecart", successResponse.data.order_number);
      setOrderNumber(successResponse.data.order_number);
      handleClear.mutate();
      setShowSuccessModal(true);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleClear = useMutation({
    mutationFn: () =>
      axiosInstance.get(
        `${API_URL.CLEAR_CART}/${customerId}/carts/${merchantId}/clear`
      ),

    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
      }
    },
  });

  const { data: countriesData } = useQuery<Country[], Error>({
    queryKey: ["countries"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.COUNTRY);
      return response.data;
    },
  });

  const handleCountryChange = async (e: any) => {
    const selectedCountryName = e.target.value;
    setCountry(selectedCountryName);

    const selectedCountry = countriesData?.find(
      (country) => country.countryName === selectedCountryName
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
  };

  const cdwDailyRate =
    selectedItem?.product_car_insurance?.collision_damage_waiver_model
      ?.daily_collision_damage_waiver;
  const daysDifference = deliveryDetails?.daysDifference;

  let cdwAmount = 0;
  if (cdwDailyRate !== undefined && daysDifference !== undefined) {
    cdwAmount = cdwDailyRate * daysDifference;
  }

  const scdwDailyRate =
    selectedItem.product_car_insurance?.super_collision_damage_waiver_model
      ?.daily_super_collision_damage_waiver;

  let scdwAmount = 0;
  if (scdwDailyRate !== undefined && daysDifference !== undefined) {
    scdwAmount = scdwDailyRate * daysDifference;
  }

  const {
    data: customerData,
    isLoading,
    isError,
    error,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["details"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      console.log(response, "response");
      return response.data;
    },
  });

  useEffect(() => {
    if (!isLoading && customerData) {
      const { customer_details } = customerData || {};
      const { firstName, lastName, phone_no, email, address, state, country } =
        customer_details || {};

      setValue("contactAddress", address);
      setValue("firstname", firstName);
      setValue("lastname", lastName);
      setValue("phonenumber", phone_no);
      setValue("emailaddress", email);
      setValue("country", country);
      setValue("state1", state);
    }
  }, [customerData]);

  if (isLoading) return <div>Loading Customer Details...</div>;
  if (isError) return <div>Error Loading Details:{error.message}</div>;

  const CopyRegisteredInfo = async () => {
    const { customer_details } = customerData || {};
    const { firstName, lastName, phone_no, email, address, state, country } =
      customer_details || {};
    setValue("first_Name", firstName);
    setValue("last_Name", lastName);
    setValue("email_address", email);
    setValue("billingaddress", address);
    setValue("phone_number", phone_no);
    setCountry(String(country));

    const selectedCountry = countriesData?.find(
      (item) => String(item.countryName) === String(country)
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
    setState1(String(state));
  };

  const formattedDate = moment(deliveryDetails?.pickupDateDiff).format(
    "YYYY-MM-DD"
  );
  const pickUpTimeFormat = deliveryDetails?.pickupTime;

  const formattedDropoffDate = moment(deliveryDetails?.dropDateDiff).format(
    "YYYY-MM-DD"
  );
  const dropOffTimeFormat = deliveryDetails?.dropOffTime;

  const updateData: UpdateCart = {
    [merchantId]: {
      applied_discount_code: null,
      shop_information: {
        shop_address: {
          pinCode: "",
          businessName: "",
          address: "",
          landmark: "",
          city: " ",
          district: "",
          state: "",
          country: "",
          longitude: selectedItem?.merchant_latitude,
          latitude: selectedItem?.merchant_longitude,
          streetOrArea: "",
          ward: "",
          number_and_streetname: "",
          estimated_delivery_time: "",
          estimated_delivery_fee: "",
          shop_entity: "",
        },
        shop_name: selectedItem?.shop_name,
        shop_id: selectedItem?.vendor_product_inventories[0]?.vendor_id,
        image: "",
      },
      delivery_location: {
        type: "delivery_location",
        address: deliveryDetails?.formData.pickUpLocation,
        street: "",
        city: " ",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: selectedItem?.merchant_latitude,
        longitude: selectedItem?.merchant_longitude,
        landMark: "",
      },
      recollection_location: {
        type: "recollection_location",
        address: deliveryDetails?.formData.returnLocation,
        street: "",
        city: " ",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude: selectedItem?.merchant_latitude,
        longitude: selectedItem?.merchant_longitude,
        landMark: "",
      },
      note: "",
      matrix_user_id: "",
      //delivery_date: deliveryDetails?.pickupDateTime,
      delivery_date: `${formattedDate}T${pickUpTimeFormat}`,
      // delivery_date:
      //   moment(deliveryDetails?.pickupDateDiff).format(
      //     "YYYY-MM-DDTkk:mm:ss.sss"
      //   ) + "Z",
      recollection_date: `${formattedDropoffDate}T${dropOffTimeFormat}`,
      // recollection_date:
      //   moment(deliveryDetails?.dropDateDiff).format(
      //     "YYYY-MM-DDTkk:mm:ss.sss"
      //   ) + "Z",
      //recollection_date: deliveryDetails?.dropDateTime,
      order_total_amount: 1000,
      delivered_by: deliveryData?.deliveredBy,
      rental_days: deliveryDetails?.daysDifference,
      products: [
        {
          price: selectedItem?.vendor_product_inventories[0]?.price,
          toppings: [],
          product_id: selectedItem?.product_id,
          base_product_id: selectedItem?.product_id,
          product_name: selectedItem?.product_name,
          product_description: selectedItem?.product_details?.description,
          gst: selectedItem?.gst,
          baby_seat: optionalData?.baby_seat,
          baby_seat_amount: optionalData?.baby_seat_amount,
          price_per_day: selectedItem?.price_per_day,
          pai_insurance_amount: optionalData?.pai_insurance_amount,
          cdw_flag: optionalData?.cdw_flag,
          scdw_flag: optionalData?.scdw_flag,
          scdw_amount: optionalData?.scdw_amount,
          cdw_amount: optionalData?.cdw_amount,
          wind_shield_protection: optionalData?.wind_shield_protection,
          additional_driver: optionalData?.additional_driver,
          additional_driver_amount: optionalData?.additional_driver_amount,
          merchant_id: selectedItem?.merchant_id,
          quantity: 1,
          item_id: selectedItem?.product_id,
          buy_more_pay_less: null,
          discount_event: null,
          variant: {},
          shop_id: selectedItem?.vendor_product_inventories[0]?.vendor_id,
        },
      ],
      delivery_address: {
        type: "DELIVERY_ADDRESS",
        addressType: "",
        firstName: getValues("first_Name"),
        lastName: getValues("last_Name"),
        address: getValues("billingaddress"),
        street: "",
        city: " ",
        district: " ",
        state: state1,
        country: country,
        pinCode: "",
        mobileNumber: getValues("phone_number"),
        latitude: selectedItem?.merchant_latitude,
        longitude: selectedItem?.merchant_longitude,
        landMark: "",
        email: getValues("email_address"),
      },
    },
  };

  return (
    <>
      <div className="row">
        <div className="row  pb-3">
          <div className="col-md-8 processpayment_contact_infobox pt-2">
            <div className="ps-4 pt-4">
              <div className="row">
                <div className="d-flex">
                  <div>
                    <img
                      src={registered_contact_logo}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="payment_contactinfo_text ps-2">
                    Registered Contact Info
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    First Name
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <CustomInput
                    inputName="firstname"
                    inputType="firstname"
                    editable={false}
                    control={control}
                    className="contactinfo_text_input_style"
                    placeholder="Alex"
                    onInputChange={(value: string) => {}}
                  />
                </div>
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Last Name
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="lastname"
                      inputType="lastname"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="John"
                      onInputChange={(value: string) => {}}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Email Address
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="emailaddress"
                      inputType="emailaddress"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="alexjohn2021@gmail.com"
                      onInputChange={(value: string) => {}}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Contact Address
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="contactAddress"
                      inputType="contactAddress"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder=" Space Building 21, Karama."
                      onInputChange={(value: string) => {}}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Country
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="country"
                      inputType="country"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder=" Space Building 21, Karama."
                      onInputChange={(value: string) => {}}
                    />
                  </div>
                </div>
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    State<span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="state1"
                      inputType="state1"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder=" Space Building 21, Karama."
                      onInputChange={(value: string) => {}}
                    />
                  </div>
                </div>
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Phone Number
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="inputContainerStyle text-end">
                    <span className="payment_new_country_codestyle ">+971</span>
                    <CustomInput
                      inputName="phonenumber"
                      inputType="phonenumber"
                      editable={false}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="+971 5548855"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8  ">
            <div className="d-flex float-end pt-2 pb-2">
              <div className="pe-2">
                <Copy
                  color="white"
                  size={"25"}
                  onClick={CopyRegisteredInfo}
                  cursor={"pointer"}
                />
              </div>
              <div>
                <a
                  className="copy_addressdelivery"
                  onClick={CopyRegisteredInfo}
                >
                  Delivery Address is the same as Contact Address
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row  pb-5 pt-3">
          <div className="col-md-8 processpayment_contact_infobox">
            <div className="ps-4 pt-4">
              <div className="row">
                <div className="d-flex">
                  <div>
                    <img
                      src={address_delivery_icon}
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="address_delivery_text ps-2 ">
                    Address for Delivery
                  </div>
                </div>
              </div>
              {/* <div className="row  pt-2 ps-4">
                <p className="address_delivery_subtext ps-5">
                  If Billing / Shipping address is different from registered
                  address Kindly fill the below fields manually.
                  <br />
                  <br />
                  If the Billing / Shipping address is same click the “Copy
                  Registered info” hyperlink above so it will copy the same to
                  Billing / Shipping Section here.
                </p>
              </div> */}
              <div className="row ps-2">
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    First Name
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="first_Name"
                      inputType="first_Name"
                      editable={true}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="Alex"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Last Name
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="last_Name"
                      inputType="last_Name"
                      editable={true}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="John"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
              </div>

              <div className="row ps-2">
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Email Address
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="email_address"
                      inputType="email_address"
                      editable={true}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="alexjohn2021@gmail.com"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Billing Address
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="text-start">
                    <CustomInput
                      inputName="billingaddress"
                      inputType="billingaddress"
                      editable={true}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder=" Space Building 21, Karama."
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
              </div>
              <div className="row  ps-2 mb-5">
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Country
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <select
                    className="contactinfo_text_input_style   bagroundcolor"
                    onChange={handleCountryChange}
                    value={country}
                  >
                    <option
                      value=""
                      key="select"
                      className="business_page_right_side_font"
                    >
                      Select Country
                    </option>
                    {countriesData &&
                      countriesData.map((country) => (
                        <option
                          key={country.countryId}
                          value={country.countryName}
                        >
                          {country.countryName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    State
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <select
                    className="contactinfo_text_input_style bagroundcolor"
                    value={state1}
                    onChange={(e) => setState1(e.target.value)}
                  >
                    <option
                      value=""
                      key="select"
                      className="business_page_right_side_font"
                    >
                      Select State
                    </option>
                    {stateData &&
                      stateData.map((state) => (
                        <option key={state.id} value={state.state_name}>
                          {state.state_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 text-start pt-4">
                  <div className="contactinfo_text_label">
                    Phone Number
                    <span className="contactinfo_text_labelstar">*</span>
                  </div>
                  <div className="inputContainerStyle text-end">
                    <span className="payment_new_country_codestyle ">+971</span>{" "}
                    <CustomInput
                      inputName="phone_number"
                      inputType="phone_number"
                      editable={true}
                      control={control}
                      className="contactinfo_text_input_style"
                      placeholder="+971 5548855"
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.priceperday}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* second component */}

          <div className="col-md-4 pt-2">
            {/* <RentalSummary /> */}

            <RentalSummarySecondPage
              daysDifference={deliveryDetails.daysDifference}
              totalAmount={deliveryDetails.totalAmount}
              childSeatAmount={
                selectedItem.product_details_car.child_seat_amount
              }
              onDataFromRentalSummary={(data: any) => {
                console.log("Data from Rental Summary:", data);
              }}
              paiInsuranceAmount={
                selectedItem.product_car_insurance
                  ?.personal_accident_insurance_model
                  ?.daily_personal_accident_insurance
              }
              // cdwAmount={
              //   selectedItem.product_car_insurance
              //     ?.collision_damage_waiver_model?.daily_collision_damage_waiver
              // }
              cdwAmount={cdwAmount}
              scdwAmount={scdwAmount}
              deliveryFee={deliveryFee}
              // scdwAmount={
              //   selectedItem.product_car_insurance
              //     ?.super_collision_damage_waiver_model
              //     ?.daily_super_collision_damage_waiver
              // }
              carImage={selectedItem.product_details.images[0]}
              carModel={selectedItem.product_details_car.car_model}
              seat={selectedItem.product_details_car.number_of_seats}
              fuelType={selectedItem.product_details_car.fuel_type}
              isLastPage={true}
              wtpAmount={
                selectedItem?.product_details_car?.wind_shield_protection
              }
            />
          </div>
          <div className="col-md-12 d-flex justify-content-end ms-2">
            <div className="col-md-4 text-end pt-4">
              <button
                className="btn all_button_style text-black textsize mx-2"
                type="button"
                onClick={handleSubmit}
              >
                CONFIRM AND PAY
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/customer/selectpayment");
        }}
        message={`Your Order Placed Successfully<br /><span style="font-size: 18px">${orderNumber}</span>`}
      />

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="shop currently in offine"
      />
    </>
  );
};

export default ProcessPayment;
