import React, { useState } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  dashboard_search_normal,
  kirkos_merchant_profile,
} from "../../../assets/images";
import { useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance, { afl_log_out } from "../../../services/axios.instance";
import { CustomerDetails } from "../../../types";

interface Props {
  currentPage: string;
}

const AflNavbar: React.FC<Props> = ({ currentPage }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [profileImage, setProfileImage] = useState<string>(
    kirkos_merchant_profile
  );

  const {
    data: customerData,
    error,
    refetch,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["navprofiledetails"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      const details = response.data;
      setProfileImage(details?.merchant_details?.siteLogo);
      return details;
    },
  });

  const handleProfile = () => {
    setShowProfile(!showProfile);
  };
  const handleSetActiveNavigationLogout = (nav: string) => {
    afl_log_out();
  };

  return (
    <div className="nav-bar-style">
      {/* <ToastContainer className={"custom-toast"} /> */}
      <Navbar
        expand="lg"
        className="nav-pills navbar-light nav-tab-style justify-content-end"
      >
        <Navbar.Collapse>
          <div className="col navheaderdiv">
            <Navbar.Brand href="#" className="navbar-brand-style">
              {currentPage === "dashboard"
                ? "Dashboard"
                : currentPage === "servicelist"
                ? "Manage Services  / Listings"
                : currentPage === "servicecreation"
                ? "Create Service  / Request"
                : ""}
            </Navbar.Brand>

            <Navbar.Text>
              <a className="nav-link brand-bottom " href="#">
                {currentPage === "servicelist" ? (
                  <span>
                    <span className="brand-bottom-first">Dashboard &gt; </span>
                    <span className="navspan-bg-color">
                      Manage Services / Listings
                    </span>
                  </span>
                ) : currentPage === "servicecreation" ? (
                  <>
                    <span className="brand-bottom-first">
                      Dashboard&nbsp;&gt;&nbsp;&nbsp;&gt;&nbsp;
                    </span>
                    <span className="navspan-bg-color">
                      Create Service / Request
                    </span>
                  </>
                ) : null}
              </a>
            </Navbar.Text>
          </div>
        </Navbar.Collapse>
        <Nav className="right-side-nav justify-content-end">
          <div className="justify-content-end d-inline-flex">
            {/* <li className="nav-item nav-bar-li">
                            <a className="nav-link search-bar-bg p-1">
                                <form className="d-flex">
                                    <label htmlFor="searchbox">
                                        <img src={dashboard_search_normal} width="30" height="30" />
                                    </label>
                                    <input
                                        id="searchbox"
                                        type="text"
                                        className="search-bar-input-box px-1"
                                        placeholder="Search your car activity log"
                                    />
                                </form>
                            </a>
                        </li> */}

            {/* <Dropdown
              show={showNotification}
              onToggle={handleNotification}
              style={{ width: 55 }}
              className="pt-1"
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="notification-hover p-0 relative-div "
              >
                <div
                  className={notifications.length > 0 ? "icon-bell-hover" : ""}
                >
                  <Bell size={20} color=" white " />
                </div>

                {newNotificationsCount > 0 && (
                  <div className="notification-badge icon-hover ">
                    {newNotificationsCount}
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="display-drop-down-notification">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <Dropdown.Item
                      className="word-in-line "
                      key={index}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="">{notification}</div>
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item className="word-in-line">
                    No new notifications
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown
              show={showProfile}
              onToggle={handleProfile}
              style={{ width: 100 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="profile-hover pt-1 px-2 profile-afl"
              >
                <img
                  src={profileImage || kirkos_merchant_profile}
                  className="rounded-pill profile-logo"
                  alt=""
                />
                <span className="custom-d-none-navbar">&nbsp;Logout</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="display-drop-down">
                {/* <Dropdown.Item as={Link} to="/dashboard/myprofile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#">
                  Edit
                </Dropdown.Item> */}
                <Dropdown.Item
                  as={Link}
                  to="/afl"
                  onClick={() => handleSetActiveNavigationLogout("logout")}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default AflNavbar;
