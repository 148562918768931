import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./Pages/Css/variable.css";
import "./Pages/Css/responsive.scss";
import RouterComponent from "./routes";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <RouterComponent />
          <div className="custom-toast">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
            />
          </div>
        </div>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
