import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput";
import { useForm } from "react-hook-form";
import { dashboard_invoice_approve } from "../../../assets/images";
//import "./orderedit.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceData, ServiceType } from "../../../types";
import SuccessModal from "../../../components/SuccessModal";
import AuthRules from "../../../rules/AuthRules";
import rules from "../../../rules";
import ErrorModal from "../../../components/ErrorModal";

interface LocationData {
  latitude: number;
  longitude: number;
}

interface RecollectionLocationData {
  Recollectionlatitude: number;
  Recollectionlongitude: number;
}

const ServiceCreation = () => {
  const location = useLocation();

  //----------------------------
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("");

  const [selectedPlateNum, setSelectedPlateNum] = useState("");
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [isOtherMakeSelected, setIsOtherMakeSelected] = useState(false);
  const [customMake, setCustomMake] = useState("");
  const [customModel, setCustomModel] = useState<string>("");
  const [comments, setComments] = useState("");

  //--------------------------------

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: 0,
    longitude: 0,
  });
  const [selectedRecollectionLocation, setSelectedRecollectionLocation] =
    useState<RecollectionLocationData>({
      Recollectionlatitude: 0,
      Recollectionlongitude: 0,
    });

  const [recollectionSearchQuery, setRecollectionSearchQuery] =
    useState<string>("");
  const [recollectionAddressSuggestions, setRecollectionAddressSuggestions] =
    useState<string[]>([]);
  const [recollectionSuggestionClicked, setRecollectionSuggestionClicked] =
    useState(false);

  const [isChecked, setIsChecked] = useState(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [RefNumber, setRefNumber] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // const [toLocations, setToLocations] = useState<string[]>([]);
  //const [fromLocations, setFromLocations] = useState<string[]>([]);

  //const [selectedToLocation, setSelectedToLocation] = useState("");
  ///const [selectedFromLocation, setSelectedFromLocation] = useState("");

  //-----------------------------
  const [toLocations, setToLocations] = useState<
    { location: string; latitude: number | null; longitude: number | null }[]
  >([]);
  const [fromLocations, setFromLocations] = useState<
    { location: string; latitude: number | null; longitude: number | null }[]
  >([]);

  const [selectedToLocation, setSelectedToLocation] = useState("");
  const [selectedFromLocation, setSelectedFromLocation] = useState("");

  const [selectedToLatLon, setSelectedToLatLon] = useState<{
    latitude: number | null;
    longitude: number | null;
  } | null>(null);

  const [selectedFromLatLon, setSelectedFromLatLon] = useState<{
    latitude: number | null;
    longitude: number | null;
  } | null>(null);

  //----------------------------------
  const navigate = useNavigate();

  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  //----------------------------------------

  const { data: serviceType } = useQuery<ServiceType[], Error>({
    queryKey: ["serviceType"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.SERVICE_TYPES);
      return response.data;
    },
  });

  //   const handleServiceTypeChange = (event: any) => {
  //     const selectedValue = event.target.value;
  //     setSelectedService(selectedValue);
  //     console.log("SER", selectedValue);
  //   };
  const handleServiceTypeChange = (event: any) => {
    const selectedValue = event.target.value;
    const selectedServiceData = serviceType?.find(
      (type) => type?.serviceName === selectedValue
    );

    if (selectedServiceData) {
      // Reset all location related states
      setToLocations([]);
      setSelectedService(selectedServiceData.serviceName);
      setSelectedServiceType(selectedServiceData.serviceType);
      setShowOtherLocation(false);
      setOtherLatitude("");
      setOtherLongitude("");
      setSelectedToLocation("");
      setSelectedToLatLon(null);

      // Pass 'ALL' as vehicle_type if 'Others' is selected for make, otherwise pass selectedMake
      handleGetLocationType.mutate({
        vehicle_type: isOtherMakeSelected ? "ALL" : selectedMake,
      });
    }
  };

  //---------------------------------------------

  const { data: emiratesCode } = useQuery<any[], Error>({
    queryKey: ["emiratesCode"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.EMIRATES_CODE);
      return response.data;
    },
  });

  const handlePlateNumChange = (event: any) => {
    const selectedPlatenumValue = event.target.value;
    setSelectedPlateNum(selectedPlatenumValue);
    console.log("EM CODE", selectedPlatenumValue);
  };

  //------------------------------------------------

  //------------------------------------

  const {
    data: makeModelList,
    isLoading,
    error,
  } = useQuery<Record<string, string[]>, Error>({
    queryKey: ["makeModelList"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.MAKE_MODEL_LIST);
      return response.data;
    },
  });

  const handleMakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    if (selectedValue === "Others") {
      setIsOtherMakeSelected(true);
      setSelectedMake("");
      setSelectedModel("");
    } else {
      setIsOtherMakeSelected(false);
      setSelectedMake(selectedValue);
      setSelectedModel("");
    }
  };
  const [scheduleType, setScheduleType] = useState<string>("");

  const handleScheduleTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setScheduleType(event.target.value);
  };

  const handleCustomMakeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomMake(event.target.value);
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(event.target.value);
  };
  const handleCustomModelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomModel(event.target.value);
  };

  const handleCommentsChange = (event: any) => {
    setComments(event.target.value);
  };

  //------------------------------------
  // const [dateTime, setDateTime] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [minDateTime, setMinDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const localNow = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const isoString = localNow.toISOString().substring(0, 16);
    setMinDateTime(isoString);

    if (scheduleType === "schedule_for") {
      localNow.setHours(localNow.getHours() + 2);
      setDateTime(localNow.toISOString().substring(0, 16));
    } else if (scheduleType === "schedule_now") {
      setDateTime(isoString);
    }
  }, [scheduleType]);

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateTime(event.target.value);
  };

  //--------------------------------------------------------

  //-------------------------------------------

  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setSuggestionClicked(false);
  };

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const { data: locationName } = useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });
  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("Location", selectedSuggestion);
    setAddressSuggestions([]);
    setSuggestionClicked(true);
  };

  //--------------------------------------------------------------------
  //===========================================================================

  const handleRecollectionInputChange = (value: string) => {
    setRecollectionSearchQuery(value);
    setRecollectionSuggestionClicked(false);
  };

  const fetchRecollectionLocationName = async (
    recollectionSearchQuery: string
  ) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${recollectionSearchQuery}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setRecollectionAddressSuggestions(suggestions);
    return data[0]?.display_name;
  };

  const { data: recollectionLocationName } = useQuery<string>({
    queryKey: ["recollectionLocationName", recollectionSearchQuery],
    queryFn: () => fetchRecollectionLocationName(recollectionSearchQuery),
  });

  const handleRecollectionSuggestionClick = async (
    selectedRecollectionSuggestion: string
  ) => {
    const response = await fetch(
      `https://nominatim.kirkos.ae/search.php?q=${selectedRecollectionSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedRecollectionLocation({
        Recollectionlatitude: parseFloat(lat),
        Recollectionlongitude: parseFloat(lon),
      });
    }
    setValue("RecollectionLocation", selectedRecollectionSuggestion);
    setRecollectionAddressSuggestions([]);
    setRecollectionSuggestionClicked(true);
  };

  //===========================================================================

  //------------------------------------------------------------------------------
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const fetchLocationNameFromCoords = async (lat: string, lon: string) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json&accept-language=en`
      );
      const data = await response.json();
      return data.display_name;
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "";
    }
  };

  const handleUpdate = () => {
    const formData = getValues();
    console.log("SERVICE REQ:", formData);
    console.log("button clicked");
    console.log("new test----->", selectedFromLocation);
    const recollectionLatitude =
      selectedRecollectionLocation?.Recollectionlatitude;
    const recollectionLongitude =
      selectedRecollectionLocation?.Recollectionlongitude;

    const userData: ServiceData = {
      reference_number: formData?.RefNumber,
      vehicle_make: selectedMake || customMake,
      vehicle_model: selectedModel || customModel,
      plate_number: formData?.RegNumber,
      reg_number: "",
      person_name: formData?.PersonName,
      mobile_number: formData?.phoneNo,
      service_name: selectedService,
      service_based: selectedServiceType,
      ...(scheduleType === "schedule_now" && { is_scheduled_now: true }),
      ...(scheduleType === "schedule_for" && { is_scheduled_now: false }),

      ...(selectedServiceType !== "LOCATION_BASED" && {
        location_from: {
          address:
            selectedService === "CUSTOMER DROP"
              ? selectedFromLocation
              : getValues("RecollectionLocation") || selectedFromLocation,
          city: "",
          street: "",
          district: "",
          state: "",
          country: "",
          pinCode: "",
          latitude:
            selectedService === "CUSTOMER DROP"
              ? selectedFromLatLon?.latitude
              : recollectionLatitude !== 0
              ? recollectionLatitude?.toString() || selectedToLatLon?.latitude
              : showOtherLocation
              ? otherLatitude
              : selectedToLatLon?.latitude,
          longitude:
            selectedService === "CUSTOMER DROP"
              ? selectedFromLatLon?.longitude
              : recollectionLongitude !== 0
              ? recollectionLongitude?.toString() || selectedToLatLon?.longitude
              : showOtherLocation
              ? otherLongitude
              : selectedToLatLon?.longitude,
          landMark: "",
        },
      }),

      location_to: {
        address: getValues("RecollectionLocation") || selectedToLocation,
        city: "",
        type: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude:
          recollectionLatitude !== 0
            ? recollectionLatitude?.toString() || selectedToLatLon?.latitude
            : showOtherLocation
            ? otherLatitude
            : selectedToLatLon?.latitude,

        longitude:
          recollectionLongitude !== 0
            ? recollectionLongitude?.toString() || selectedToLatLon?.longitude
            : showOtherLocation
            ? otherLongitude
            : selectedToLatLon?.longitude,

        landMark: "",
      },

      scheduled_for: dateTime,
      afl_comments: comments,
      emirate: selectedPlateNum,
    };

    if (
      (selectedLocation &&
        (selectedLocation.latitude || selectedLocation.longitude)) ||
      selectedToLocation
    ) {
      userData.location_from = {
        address: getValues("Location") || selectedToLocation,
        city: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",
        latitude:
          selectedService === "CUSTOMER DROP"
            ? otherLatitude
            : selectedLocation?.latitude !== 0
            ? selectedLocation?.latitude?.toString() ||
              selectedFromLatLon?.latitude
            : selectedFromLatLon?.latitude,
        longitude:
          selectedService === "CUSTOMER DROP"
            ? otherLongitude
            : selectedLocation?.longitude !== 0
            ? selectedLocation?.longitude?.toString() ||
              selectedFromLatLon?.longitude
            : selectedFromLatLon?.longitude,
        // latitude: selectedLocation.latitude?.toString() || selectedFromLatLon?.latitude,
        //longitude: selectedLocation.longitude?.toString() || selectedFromLatLon?.latitude,
        landMark: "",
      };
    }

    console.log("userData:", userData);

    handleServiceCreation.mutate(userData);
  };

  const handleServiceCreation = useMutation({
    mutationFn: (userData: ServiceData) =>
      axiosInstance.post(`${API_URL.CREATE_SERVICE}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      console.log("successResponseproduct", successResponse);
      if (successResponse.status && successResponse?.data) {
        setRefNumber(successResponse.data.reference_number || ""); // Store ERP order number
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const handleGetLocationType = useMutation({
    mutationFn: async (userData: { vehicle_type: string }) =>
      axiosInstance.post(`${API_URL.GET_LOCATIONS}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      console.log("API Response: locationss", successResponse);
      const locations = successResponse?.data;

      if (locations?.length > 0) {
        setToLocations(
          locations
            .filter((item: any) => item.toLocation) // Ensure non-null values
            .map((item: any) => ({
              location: item.toLocation,
              latitude: item.latitude,
              longitude: item.longitude,
            }))
        );

        setFromLocations(
          locations
            .filter((item: any) => item.toLocation) // Ensure non-null values
            .map((item: any) => ({
              location: item.toLocation,
              latitude: item.latitude,
              longitude: item.longitude,
            }))
        );
      }
    },
    onError: (error: any) => {
      console.error("Error fetching locations:", error);
    },
  });

  const [showOtherLocation, setShowOtherLocation] = useState(false);
  const [otherLatitude, setOtherLatitude] = useState<string>("");
  const [otherLongitude, setOtherLongitude] = useState<string>("");

  const handleToLocationChange = (selectedLocation: string) => {
    setSelectedToLocation(selectedLocation);

    if (selectedLocation === "Other") {
      setShowOtherLocation(true);
      setSelectedToLatLon(null);
    } else {
      setShowOtherLocation(false);
      // Find the corresponding lat/lon
      const locationData = toLocations.find(
        (loc) => loc.location === selectedLocation
      );

      if (locationData) {
        setSelectedToLatLon({
          latitude: locationData.latitude,
          longitude: locationData.longitude,
        });
      } else {
        setSelectedToLatLon(null);
      }
    }
  };

  const handleOtherLatitudeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLat = e.target.value;
    setOtherLatitude(newLat);
    console.log("test----->", otherLatitude);

    // Only fetch location name if both lat and lon are present
    if (newLat && otherLongitude) {
      fetchLocationNameFromCoords(newLat, otherLongitude).then(
        (locationName) => {
          setSelectedToLocation(locationName);
        }
      );
    }

    setSelectedToLatLon({
      latitude: Number(newLat),
      longitude: selectedToLatLon?.longitude || null,
    });
  };

  const handleOtherLongitudeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLon = e.target.value;
    setOtherLongitude(newLon);
    console.log("testtt----->", otherLongitude);

    // Only fetch location name if both lat and lon are present
    if (otherLatitude && newLon) {
      fetchLocationNameFromCoords(otherLatitude, newLon).then(
        (locationName) => {
          setSelectedToLocation(locationName);
        }
      );
    }

    setSelectedToLatLon({
      latitude: selectedToLatLon?.latitude || null,
      longitude: Number(newLon),
    });
  };

  const handleFromLocationChange = (selectedLocation: string) => {
    setSelectedFromLocation(selectedLocation);

    if (selectedLocation === "Other") {
      setShowOtherLocation(true);
      setSelectedFromLatLon(null);
    } else {
      setShowOtherLocation(false);
      // Find the corresponding lat/lon
      const locationData = toLocations.find(
        (loc) => loc.location === selectedLocation
      );

      if (locationData) {
        setSelectedFromLatLon({
          latitude: locationData.latitude,
          longitude: locationData.longitude,
        });
      } else {
        setSelectedFromLatLon(null);
      }
    }
    // Find the corresponding lat/lon
    const locationData = fromLocations.find(
      (loc) => loc.location === selectedLocation
    );

    if (locationData) {
      setSelectedFromLatLon({
        latitude: locationData.latitude,
        longitude: locationData.longitude,
      });
    } else {
      setSelectedFromLatLon(null);
    }
  };

  //=====================================================

  const isButtonDisabled = (): boolean => {
    if (!isValid) {
      return true;
    }

    const isMakeValid = !!(customMake.trim() || selectedMake);
    const isModelValid = !!(customModel.trim() || selectedModel);

    const isLocationBasedValid = !!(
      selectedRecollectionLocation?.Recollectionlatitude ||
      selectedToLatLon?.latitude
    );

    const isDistanceBasedValid = !!(
      selectedLocation.latitude ||
      selectedFromLatLon?.latitude ||
      selectedToLatLon?.latitude
    );

    if (selectedServiceType === "LOCATION_BASED") {
      return (
        // !selectedRecollectionLocation ||
        // !selectedRecollectionLocation.Recollectionlatitude ||
        !isLocationBasedValid ||
        !selectedPlateNum ||
        !isMakeValid ||
        !isModelValid ||
        !selectedService ||
        !selectedServiceType ||
        !scheduleType
      );
    }
    if (selectedServiceType === "DISTANCE_BASED") {
      return (
        // !selectedLocation ||
        // !selectedLocation.latitude ||
        // !selectedRecollectionLocation ||
        // !selectedRecollectionLocation.Recollectionlatitude ||
        !isDistanceBasedValid ||
        !isLocationBasedValid ||
        !selectedPlateNum ||
        !isMakeValid ||
        !isModelValid ||
        !selectedService ||
        !selectedServiceType ||
        !scheduleType
      );
    }
    return true;
  };
  //-------------------------------------------------------------------------

  return (
    <div className="row afl-custom">
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row   pb-3">
              <div className="col-md-12 pt-4">
                <div className="row">
                  <div className="row pt-2">
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Ref.No<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="RefNumber"
                        inputType="text"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        // rules={rules.AuthRules.productNumber}
                      />
                    </div>

                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Make <span className="merchantStar">*</span>
                      </label>
                      {!isOtherMakeSelected ? (
                        <select
                          className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                          onChange={handleMakeChange}
                          value={selectedMake}
                        >
                          <option value="" key="select-make">
                            Select
                          </option>
                          {makeModelList &&
                            Object.keys(makeModelList).map((make) => (
                              <option value={make} key={make}>
                                {make}
                              </option>
                            ))}
                          <option value="Others" key="others">
                            Others
                          </option>
                        </select>
                      ) : (
                        <div className="position-relative d-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Make"
                            value={customMake}
                            onChange={handleCustomMakeChange}
                          />
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              setIsOtherMakeSelected(false);
                              setCustomMake("");
                              setCustomModel("");
                              setSelectedMake("");
                            }}
                            style={{
                              border: "none",
                              background: "transparent",
                              color: "red",
                            }}
                          >
                            x
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Model <span className="merchantStar">*</span>
                      </label>
                      {!isOtherMakeSelected ? (
                        <select
                          className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                          onChange={handleModelChange}
                          value={selectedModel}
                          disabled={!selectedMake}
                        >
                          <option value="" key="select-model">
                            Select
                          </option>
                          {selectedMake &&
                            makeModelList?.[selectedMake]?.map(
                              (model, index) => (
                                <option value={model} key={index}>
                                  {model}
                                </option>
                              )
                            )}
                        </select>
                      ) : (
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Model"
                            value={customModel}
                            onChange={handleCustomModelChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Plate No<span className="merchantStar">*</span>
                      </label>

                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        value={selectedPlateNum}
                        onChange={handlePlateNumChange}
                      >
                        <option value="">Select</option>
                        {emiratesCode?.map((code, index) => (
                          <option key={index} value={code}>
                            {code}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Reg.No<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="RegNumber"
                        inputType="RegNumber"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.productDescription}
                      />
                    </div>
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Person Name<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="PersonName"
                        inputType="PersonName"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        rules={rules.AuthRules.productDescription}
                      />
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Mobile<span className="merchantStar">*</span>
                      </label>
                      <CustomInput
                        inputName="phoneNo"
                        inputType="text"
                        className="merchant-input-style-create-product"
                        placeholder=""
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        maxLength={9}
                        rules={rules.AuthRules.phone_number}
                      />
                    </div>
                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Service Type<span className="merchantStar">*</span>
                      </label>

                      <select
                        className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                        value={selectedService}
                        onChange={handleServiceTypeChange}
                        disabled={!selectedMake || !selectedModel}
                      >
                        <option value="">Select</option>
                        {serviceType?.map((type) => (
                          <option key={type.id} value={type.serviceName}>
                            {type.serviceName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedServiceType === "DISTANCE_BASED" && (
                      <div className="col-md-6 pt-3 relative-div">
                        <label className="form-label dashboard-poppins-font-style">
                          Location From<span className="merchantStar">*</span>
                        </label>
                        {selectedServiceType !== "DISTANCE_BASED" ||
                        selectedService === "CUSTOMER DROP" ? (
                          <select
                            className="merchant-input-style-create-product"
                            value={selectedFromLocation}
                            onChange={(e) =>
                              handleFromLocationChange(e.target.value)
                            }
                            disabled={!selectedMake || !selectedModel}
                          >
                            <option value="" disabled>
                              Select From Location
                            </option>
                            {selectedService === "CUSTOMER DROP" && (
                              <option value="Other">Other</option>
                            )}
                            {fromLocations.map((loc, index) => (
                              <option key={index} value={loc.location}>
                                {loc.location}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>
                            <CustomInput
                              inputName="Location"
                              inputType="Location"
                              className="merchant-input-style-create-product"
                              placeholder="From Location"
                              editable={true}
                              onInputChange={(value: string) =>
                                handleInputChange(value)
                              }
                              control={control}
                            />
                            {addressSuggestions?.length > 0 && (
                              <div className="absolute-div">
                                <ul
                                  className="text-start"
                                  style={{ color: "#333132" }}
                                >
                                  {addressSuggestions.map(
                                    (suggestion, index) => (
                                      <li
                                        key={index}
                                        onClick={() =>
                                          handleSuggestionClick(suggestion)
                                        }
                                      >
                                        {suggestion}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div className="col-md-6 pt-3 relative-div">
                      <label className="form-label dashboard-poppins-font-style">
                        Location To<span className="merchantStar">*</span>
                      </label>
                      {selectedServiceType === "DISTANCE_BASED" &&
                      selectedService !== "CUSTOMER DROP" ? (
                        <div>
                          <select
                            className="merchant-input-style-create-product"
                            value={selectedToLocation}
                            onChange={(e) =>
                              handleToLocationChange(e.target.value)
                            }
                            disabled={!selectedMake || !selectedModel}
                          >
                            <option value="" disabled>
                              Select To Location
                            </option>
                            {(selectedService === "INTERNAL MOVEMENTS" ||
                              selectedService === "AFL INTERNAL JOB" ||
                              selectedService === "TOWING SERVICES" ||
                              selectedService === "ACCIDENT TOWING" ||
                              selectedService === "CUSTOMER COLLECT") && (
                              <option value="Other">Other</option>
                            )}
                            {toLocations.map((loc, index) => (
                              <option key={index} value={loc.location}>
                                {loc.location}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <>
                          <CustomInput
                            inputName="RecollectionLocation"
                            inputType="RecollectionLocation"
                            className="merchant-input-style-create-product"
                            placeholder="To Location"
                            editable={true}
                            onInputChange={(value: string) =>
                              handleRecollectionInputChange(value)
                            }
                            control={control}
                          />

                          {recollectionAddressSuggestions?.length > 0 && (
                            <div className="absolute-div">
                              <ul
                                className="text-start"
                                style={{ color: "#333132" }}
                              >
                                {recollectionAddressSuggestions?.map(
                                  (suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleRecollectionSuggestionClick(
                                          suggestion
                                        )
                                      }
                                    >
                                      {suggestion}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {showOtherLocation && (
                      <>
                        <div className="col-md-6 pt-3">
                          <input
                            type="number"
                            className="form-control merchant-input-style-create-product"
                            placeholder="Latitude"
                            value={otherLatitude}
                            onChange={handleOtherLatitudeChange}
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <input
                            type="number"
                            className="form-control merchant-input-style-create-product"
                            placeholder="Longitude"
                            value={otherLongitude}
                            onChange={handleOtherLongitudeChange}
                          />
                        </div>
                        {selectedToLocation &&
                          otherLatitude &&
                          otherLongitude && (
                            <div className="col-12 pt-3">
                              <textarea
                                className="form-control merchant-input-style-create-product"
                                value={selectedToLocation}
                                readOnly
                                rows={2}
                                placeholder="Location name will appear here..."
                                style={{ resize: "none" }}
                              />
                            </div>
                          )}
                      </>
                    )}

                    <div className="col-md-6 pt-3">
                      <label className="form-label dashboard-poppins-font-style">
                        Schedule Type<span className="merchantStar">*</span>
                      </label>
                      <select
                        className="merchant-input-style-create-product"
                        value={scheduleType}
                        onChange={handleScheduleTypeChange}
                        disabled={!selectedMake || !selectedModel}
                      >
                        <option>Select</option>
                        <option value="schedule_for">Schedule For</option>
                        <option value="schedule_now">Schedule Now</option>
                      </select>

                      {scheduleType === "schedule_for" && (
                        <div className="mt-3">
                          <label className="form-label dashboard-poppins-font-style">
                            Schedule For<span className="merchantStar">*</span>
                          </label>
                          <div className="input-date-container">
                            <input
                              type="datetime-local"
                              id="datetime"
                              value={dateTime}
                              onChange={handleDateTimeChange}
                              min={minDateTime}
                              className="merchant-input-style-create-product"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <label className="form-label dashboard-poppins-font-style">
                    Comments
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    rows={5}
                    value={comments}
                    onChange={handleCommentsChange}
                    className="select-invoice-bg-black-textarea"
                  ></textarea>
                </div>
              </div>

              <div className="col-12 pt-4 pb-3 text-end">
                <button
                  className="invoice-approve-btn px-4"
                  disabled={isButtonDisabled()}
                  onClick={handleUpdate}
                >
                  +{" "}
                  <img src={dashboard_invoice_approve} height={"14px"} alt="" />
                  &nbsp;Create Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/dashboard/servicelist");
          setShowSuccessModal(false);
        }}
        message={`Created Successfully \n Ref Number: ${RefNumber}`}
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />
    </div>
  );
};

export default ServiceCreation;
