import React, { useEffect, useState } from "react";
import CustomInput from "../../CustomInput";
import { FieldValues, useForm } from "react-hook-form";
import { dashboard_invoice_approve } from "../../../assets/images";
import "./orderedit.css";
import CustomCheckBox from "../../customCheckbox";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DeliverySchedule,
  DriverAmount,
  ExtraAmount,
  OrderEditData,
} from "../../../types";
import SuccessModal from "../../SuccessModal";
import moment from "moment";

interface LocationData {
  latitude: number;
  longitude: number;
}

const AcceptedorderDetailsEdit = () => {
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  const [status, setStatus] = useState<string>("");
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedpickupby, setSelectedpickupby] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const [stateTouched, setStateTouched] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: 0,
    longitude: 0,
  });
  const [selectedRecollectionLocation, setSelectedRecollectionLocation] =
    useState<LocationData>({
      latitude: 0,
      longitude: 0,
    });

  const [recollectionSearchQuery, setRecollectionSearchQuery] =
    useState<string>("");
  const [recollectionAddressSuggestions, setRecollectionAddressSuggestions] =
    useState<string[]>([]);
  const [recollectionSuggestionClicked, setRecollectionSuggestionClicked] =
    useState(false);

  const [isChecked, setIsChecked] = useState(true);
  const [isBabySeatChecked, setIsBabySeatChecked] = useState(false);
  const [isAdditionalDriverChecked, setIsAdditionalDriverChecked] =
    useState(false);
  const [isWindShieldProtectionChecked, setIsWindShieldProtectionChecked] =
    useState(false);
  const [isScdwInsuranceChecked, setIsScdwInsuranceChecked] = useState(false);
  const [isPaiInsuranceChecked, setIsPaiInsuranceChecked] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const [comments, setComments] = useState("");

  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const {
    data: editOrderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["editOrderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const handleBabySeatChange = () => {
    setIsBabySeatChecked(!isBabySeatChecked);
  };

  const handleAdditionalDriverChange = () => {
    setIsAdditionalDriverChecked(!isAdditionalDriverChecked);
  };

  const handleWindShieldProtectionChange = () => {
    setIsWindShieldProtectionChecked(!isWindShieldProtectionChecked);
  };

  const handleScdwInsuranceChange = () => {
    setIsScdwInsuranceChecked(!isScdwInsuranceChecked);
  };

  const handlePaiInsuranceChange = () => {
    setIsPaiInsuranceChecked(!isPaiInsuranceChecked);
  };
  const [defaultSlot, setDefaultSlot] = useState("");

  useEffect(() => {
    if (!isLoading && editOrderDetails) {
      const {
        delivery_start_time: delivery_start_time,
        delivery_end_time: delivery_end_time,
        rental_days: rental_days,
        delivery_preferred_time: delivery_preferred_time,
        delivered_by: delivered_by,
        delivery_date: delivery_date,
        delivery_location,
        recollection_location,
        products,
      } = editOrderDetails || {};
      const {
        address: address,
        latitude: latitude,
        longitude: longitude,
      } = delivery_location || {};
      const {
        address: recollectionAddress,
        latitude: recollectionLatitude,
        longitude: recollectionLongitude,
      } = recollection_location || {};
      const {
        productName: productName,
        babySeatAmount: babySeatAmount,
        additionalDriverAmount: additionalDriverAmount,
        windShieldProtection: windShieldProtection,
        paiInsuranceAmount: paiInsuranceAmount,
        scdwAmount: scdwAmount,
      } = products[0] || {};

      // const defaultSlotValue = `${delivery_start_time}-${delivery_preferred_time}`;
      // setDefaultSlot(defaultSlotValue);
      // setSelectedSlot(defaultSlotValue);
      // console.log("defaulttttttttttttttttttttttttttttttt", defaultSlotValue);
      // console.log("rrrrrrrrrrrrrrrrslottt", defaultSlot);
      setValue("StartSlotTime", delivery_start_time);
      setValue("EndSlotTime", delivery_end_time);
      //setSelectedSlot(formatTime(delivery_start_time));

      //setSelectedSlot(`${delivery_start_time}-${delivery_preferred_time}`);
      //setValue("NumberofDays", rental_days);
      setValue("DeliveryTime", delivery_preferred_time);
      setValue("PickupBy", delivered_by);

      // const formattedDateTime = `${
      //   new Date(delivery_date).toISOString().split("T")[0]
      // }T00:00`;
      // setDateTime(formattedDateTime);
      // console.log("date issue check", formattedDateTime);

      const formattedDateTime =
        moment(delivery_date).format("YYYY-MM-DD") + "T00:00";
      setDateTime(formattedDateTime);
      console.log("date issue check", formattedDateTime);

      setValue("Location", address);
      setValue("Latitude", latitude);
      setValue("Longitude", longitude);
      setValue("RecollectionLocation", recollectionAddress);
      setValue("RecollectionLatitude", recollectionLatitude);
      setValue("RecollectionLongitude", recollectionLongitude);
      setValue("selectedCar", productName);
      setSelectedpickupby(delivered_by);
      setIsBabySeatChecked(babySeatAmount !== 0 && babySeatAmount !== null);
      setIsAdditionalDriverChecked(
        additionalDriverAmount !== 0 && additionalDriverAmount !== null
      );
      setIsWindShieldProtectionChecked(
        windShieldProtection !== 0 && windShieldProtection !== null
      );
      setIsScdwInsuranceChecked(scdwAmount !== 0 && scdwAmount !== null);
      setIsPaiInsuranceChecked(
        paiInsuranceAmount !== 0 && paiInsuranceAmount !== null
      );

      if (!selectedLocation.latitude && !selectedLocation.longitude) {
        setSelectedLocation({ latitude, longitude });
      }
      if (
        !selectedRecollectionLocation.latitude &&
        !selectedRecollectionLocation.longitude
      ) {
        setSelectedRecollectionLocation({
          latitude: recollectionLatitude,
          longitude: recollectionLongitude,
        });
      }
    }
  }, [editOrderDetails]);

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  //--------------------------------------------------------

  const { data: deliverySlot } = useQuery<DeliverySchedule[], Error>({
    queryKey: ["slot"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.SLOT);
      return response.data;
    },
  });

  const {
    data: vehicleReassign,
    isLoading: isLoadingVehicle,
    isError: isErrorVehicle,
  } = useQuery({
    queryKey: ["vehicleList", status],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.VEHICLE_REASSIGN}/${status}`
      );
      return response.data;
    },
    enabled: !!status,
  });

  const handleCarChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedCar(selectedValue);
    // setAcceptCarConfirmationMessage(
    //   "Are you sure you want to change the vehicle?"
    // );
    // setShowCarChangeConfirmationModal(true);
  };
  const handlePickupbyChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedpickupby(selectedValue);
  };

  const handleSlotChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSlot(e.target.value);
    console.log("selectedSlot", selectedSlot);
  };

  useEffect(() => {
    if (editOrderDetails) {
      setStatus(editOrderDetails.insurance_order ? "true" : "false");
    }
  }, [editOrderDetails]);

  //-------------------------------------------

  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setSuggestionClicked(false);
  };

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https:///nominatim.kirkos.ae/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const { data: locationName } = useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });
  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https:///nominatim.kirkos.ae/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("Location", selectedSuggestion);
    setAddressSuggestions([]);
    setSuggestionClicked(true);
  };

  const handleCommentsChange = (event: any) => {
    setComments(event.target.value);
  };
  //--------------------------------------------------------------------
  //===========================================================================

  const handleRecollectionInputChange = (value: string) => {
    setRecollectionSearchQuery(value);
    setRecollectionSuggestionClicked(false);
  };

  const fetchRecollectionLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https:///nominatim.kirkos.ae/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setRecollectionAddressSuggestions(suggestions);
    return data[0]?.display_name;
  };

  const { data: recollectionLocationName } = useQuery<string>({
    queryKey: ["recollectionLocationName", recollectionSearchQuery],
    queryFn: () => fetchRecollectionLocationName(recollectionSearchQuery),
  });

  const handleRecollectionSuggestionClick = async (
    selectedSuggestion: string
  ) => {
    const response = await fetch(
      `https:///nominatim.kirkos.ae/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedRecollectionLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("RecollectionLocation", selectedSuggestion);
    setRecollectionAddressSuggestions([]);
    setRecollectionSuggestionClicked(true);
  };

  //===========================================================================

  const { data: extraAmountData } = useQuery<ExtraAmount[], Error>({
    queryKey: ["extraAmount"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.EXTRA_AMOUNT);
      return response.data;
    },
  });

  const { data: driverAmountData } = useQuery<DriverAmount[], Error>({
    queryKey: ["driverAmount"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.ADDITIONAL_DRIVER_FEE);
      return response.data;
    },
  });

  //------------------------------------------------------------------------------
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };
  const decodedToken = decodeToken(accessToken);

  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;
  const handleUpdate = () => {
    let {
      DeliveryDate: delivery_date,
      NumberofDays: extended_rental_days,
      PickupBy: pickup_by,
    }: FieldValues = getValues();

    const isoFormattedDate = `${dateTime}:00`;
    const latitude = selectedLocation?.latitude;
    const longitude = selectedLocation?.longitude;
    const recollectionLatitude = selectedRecollectionLocation?.latitude;
    const recollectionLongitude = selectedRecollectionLocation?.longitude;

    //---------------------------------------

    const datePart = dateTime.split("T")[0];
    let startTime = "";
    let endTime = "";

    if (selectedSlot) {
      const slotParts = selectedSlot.split("-");
      if (slotParts.length === 2) {
        startTime = `${datePart}T${slotParts[0].trim()}`;
        endTime = `${datePart}T${slotParts[1].trim()}`;
      }
    } else {
      const startSlotTime = getValues("StartSlotTime").split("T")[1];
      const endSlotTime = getValues("EndSlotTime").split("T")[1];
      startTime = `${datePart}T${startSlotTime}`;
      endTime = `${datePart}T${endSlotTime}`;
    }

    const getFeatureRate = (key: string, usage: string) => {
      const rate = extraAmountData?.find(
        (rate: any) => rate.key === key && rate.usage === usage
      );
      return rate ? rate.nonInsuranceRate : 0;
    };

    const getDriverRate = (deliveryType: string) => {
      const rate = driverAmountData?.find(
        (rate: any) =>
          rate.delivery_type === deliveryType && rate.amount !== null
      );
      return rate && rate.amount ? parseFloat(rate.amount) : 0;
    };

    const additionalFeatures = [];

    if (isBabySeatChecked) {
      additionalFeatures.push({
        feature: "Baby Seat",
        is_available: true,
        feature_amount: getFeatureRate("CHILD_SEAT", "DAILY"),
      });
    } else {
      additionalFeatures.push({
        feature: "Baby Seat",
        is_available: false,
        feature_amount: 0,
      });
    }

    if (isAdditionalDriverChecked) {
      additionalFeatures.push({
        feature: "Additional Driver",
        is_available: true,
        feature_amount: getDriverRate("INSURANCE"),
      });
    } else {
      additionalFeatures.push({
        feature: "Additional Driver",
        is_available: false,
        feature_amount: 0,
      });
    }

    if (isWindShieldProtectionChecked) {
      additionalFeatures.push({
        feature: "Windshield Protection",
        is_available: true,
        feature_amount: getFeatureRate("WTP", "DAILY"),
      });
    } else {
      additionalFeatures.push({
        feature: "Windshield Protection",
        is_available: false,
        feature_amount: 0,
      });
    }

    if (isScdwInsuranceChecked) {
      additionalFeatures.push({
        feature: "SCDW Insurance",
        is_available: true,
        feature_amount: getFeatureRate("SDW", "DAILY"),
      });
    } else {
      additionalFeatures.push({
        feature: "SCDW Insurance",
        is_available: false,
        feature_amount: 0,
      });
    }

    if (isPaiInsuranceChecked) {
      additionalFeatures.push({
        feature: "PAI Insurance",
        is_available: true,
        feature_amount: getFeatureRate("PAI", "DAILY"),
      });
    } else {
      additionalFeatures.push({
        feature: "PAI Insurance",
        is_available: false,
        feature_amount: 0,
      });
    }

    let request_type = "DELIVERY_DATE_EXTENSION";
    if (extended_rental_days !== editOrderDetails?.rental_days) {
      request_type = "RENTAL_DAYS_EXTENSION";
    }
    if (
      extended_rental_days !== editOrderDetails?.rental_days &&
      isoFormattedDate !== editOrderDetails?.delivery_date
    ) {
      request_type = "DELIVERY_DATE_EXTENSION";
    }

    const userData: OrderEditData = {
      order_number: OrderId,
      previous_product_id: editOrderDetails?.products[0]?.productId,
      current_product_id: selectedCar || null,
      delivery_date: isoFormattedDate,
      extended_rental_days,
      request_type,
      previous_merchant_id: merchantId,
      order_total_amount: 0,
      order_comment: comments,

      pickup_by: selectedpickupby ?? editOrderDetails?.delivered_by,

      merchant_id: merchantId,

      additional_features: additionalFeatures,

      delivery_start_time: startTime,
      delivery_end_time: endTime,

      delivery_location: {
        address: getValues("Location"),
        city: "",
        street: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",

        // latitude: latitude.toString() || getValues("Latitude") || "",
        // longitude: longitude.toString() || getValues("Longitude") || "",
        latitude: latitude ? latitude.toString() : getValues("Latitude") || "",
        longitude: longitude
          ? longitude.toString()
          : getValues("Longitude") || "",
        landMark: "",
      },
      recollection_location: {
        address: getValues("RecollectionLocation"),
        type: "",
        street: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pinCode: "",

        latitude: recollectionLatitude
          ? recollectionLatitude.toString()
          : getValues("Latitude") || "",
        longitude: recollectionLongitude
          ? recollectionLongitude.toString()
          : getValues("Longitude") || "",
        landMark: "",
      },
    };
    console.log("button clicked");

    console.log("userData:", userData);

    handleOrderEdit.mutate(userData);
  };

  const handleOrderEdit = useMutation({
    mutationFn: (userData: OrderEditData) =>
      axiosInstance.post(`${API_URL.ORDER_EDIT}`, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    onSuccess: (successResponse) => {
      console.log("successResponseproduct", successResponse);
      if (successResponse.status && successResponse?.data) {
        // alert(successResponse?.data?.message);
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        // alert(error?.data?.message);
        //setShowErrorModal(true);
      }
    },
  });

  //-------------------------------------------------------------------------
  const [dateTime, setDateTime] = useState("");
  const [minDateTime, setMinDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const isoString = now.toISOString().substring(0, 16);
    setMinDateTime(isoString);
  }, []);

  // const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDateTime(event.target.value);
  // };
  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDateTime = event.target.value;
    setDateTime(newDateTime);

    if (selectedSlot) {
      const datePart = newDateTime.split("T")[0];
      const slotParts = selectedSlot.split("-");
      if (slotParts.length === 2) {
        const newStartTime = `${datePart}T${slotParts[0].trim()}`;
        const newEndTime = `${datePart}T${slotParts[1].trim()}`;
        setSelectedSlot(`${slotParts[0].trim()}-${slotParts[1].trim()}`);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row   pb-3">
              <div className="col-md-12 pt-4">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td className="text-end">Order Created At</td>
                  </tr>
                  <tr>
                    <td className="invoice-text-yellow ">
                      # {editOrderDetails?.orderId}
                    </td>
                    <td className="text-end">
                      {formatDate(editOrderDetails?.createdAt)}
                    </td>
                  </tr>
                </table>
                <div className="row">
                  {/* <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                    Order Details
                  </div> */}
                  <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Vehicle Selection-(
                      {editOrderDetails?.products[0]?.productName})
                    </label>
                    <select
                      className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                      value={selectedCar}
                      onChange={handleCarChange}
                    >
                      <option>Select Vehicle</option>
                      {isLoadingVehicle && <option>Loading...</option>}
                      {isErrorVehicle && (
                        <option>Error loading vehicles</option>
                      )}
                      {vehicleReassign &&
                        vehicleReassign.map(
                          (vehicle: {
                            product_id: string;
                            product_name: string;
                          }) => (
                            <option
                              key={vehicle.product_id}
                              value={vehicle.product_id}
                            >
                              {vehicle.product_name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                  <div className="col-md-6 pt-3 relative-div">
                    <label className="form-label dashboard-poppins-font-style">
                      Delivery Location
                    </label>
                    <CustomInput
                      inputName="Location"
                      inputType="Location"
                      className="merchant-input-style-create-product"
                      placeholder="Location"
                      editable={true}
                      // onInputChange={(value: string) => {}}
                      onInputChange={(value: string) =>
                        handleInputChange(value)
                      }
                      control={control}
                    />
                    {addressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "white" }}>
                          {addressSuggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  {/* =================================================== */}
                  <div className="col-md-6 pt-3 relative-div">
                    <label className="form-label dashboard-poppins-font-style">
                      Recollection Location
                    </label>
                    <CustomInput
                      inputName="RecollectionLocation"
                      inputType="Location"
                      className="merchant-input-style-create-product"
                      placeholder="Recollection Location"
                      editable={true}
                      onInputChange={(value: string) =>
                        handleRecollectionInputChange(value)
                      }
                      control={control}
                    />
                    {recollectionAddressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "white" }}>
                          {recollectionAddressSuggestions.map(
                            (suggestion, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleRecollectionSuggestionClick(suggestion)
                                }
                              >
                                {suggestion}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* ================================================== */}
                  <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Delivery Date
                    </label>

                    <div className="input-date-container">
                      <input
                        type="datetime-local"
                        id="datetime"
                        value={dateTime}
                        onChange={handleDateTimeChange}
                        min={minDateTime}
                        className="merchant-input-style-create-product"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Extended Number of Days-({editOrderDetails?.rental_days})
                    </label>
                    <CustomInput
                      inputName="NumberofDays"
                      inputType="NumberofDays"
                      className="merchant-input-style-create-product"
                      placeholder=""
                      editable={true}
                      onInputChange={(value: string) => {}}
                      //onInputChange={}
                      control={control}
                    />
                  </div>

                  <div className="col-md-6 pt-3 invoice-div-data">
                    <div className="col invoice-text-yellow">
                      Delivery Slot/Time
                      <span className="merchantStar">*</span>
                    </div>
                    <select
                      className="select-invoice-bg-black-edit"
                      defaultValue={selectedSlot}
                      //defaultValue={`${editOrderDetails?.delivery_start_time}-${editOrderDetails?.delivery_preferred_time}`}
                      value={selectedSlot}
                      onChange={handleSlotChange}
                    >
                      <option value="">Select Time slot</option>
                      {isLoading && <option>Loading...</option>}
                      {isError && <option>Error loading slots</option>}
                      {deliverySlot &&
                        deliverySlot.map((slot) => (
                          <option
                            key={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                            value={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                          >
                            {`${slot.delivery_start_time} - ${slot.delivery_end_time}`}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Slot Time{" "}
                    </label>
                    <CustomInput
                      inputName="SlotTime"
                      inputType="SlotTime"
                      className="merchant-input-style-create-product"
                      placeholder="SlotTime"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      //onInputChange={}
                      control={control}
                    />
                  </div> */}

                  <div className="col-md-6 pt-3">
                    <label className="form-label dashboard-poppins-font-style">
                      Pickup By/Deliverd By
                    </label>

                    <select
                      className="select-invoice-bg-black-edit dashboard-poppins-font-style"
                      value={selectedpickupby}
                      onChange={handlePickupbyChange}
                    >
                      <option value="">Select</option>
                      <option value="KIRKOS_EXECUTIVE">KIRKOS EXECUTIVE</option>
                      <option value="CUSTOMER">CUSTOMER</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 pt-3">
                        <label className="form-label dashboard-poppins-font-style">
                          Additional Options
                        </label>
                      </div>
                      <div className="col-md-6 pt-2">
                        <div className="d-flex">
                          {/* <CustomCheckBox
                            isChecked={isChecked}
                            onChange={handleCheckboxChange}
                          /> */}
                          <CustomCheckBox
                            isChecked={isBabySeatChecked}
                            onChange={handleBabySeatChange}
                          />
                          <label className="form-check-label text-start">
                            Baby Seat
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 pt-2">
                        <div className="d-flex">
                          <CustomCheckBox
                            isChecked={isAdditionalDriverChecked}
                            onChange={handleAdditionalDriverChange}
                          />
                          <label className="form-check-label text-start">
                            Additional Driver
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 pt-2">
                        <div className="d-flex">
                          <CustomCheckBox
                            isChecked={isWindShieldProtectionChecked}
                            onChange={handleWindShieldProtectionChange}
                          />
                          <label className="form-check-label text-start">
                            WindShield Protection
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 pt-2">
                        <div className="d-flex">
                          <CustomCheckBox
                            isChecked={isScdwInsuranceChecked}
                            onChange={handleScdwInsuranceChange}
                          />
                          <label className="form-check-label text-start">
                            SCDW Insurance
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 pt-2">
                        <div className="d-flex">
                          <CustomCheckBox
                            isChecked={isPaiInsuranceChecked}
                            onChange={handlePaiInsuranceChange}
                          />
                          <label className="form-check-label text-start">
                            PAI Insurance
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <label className="form-label dashboard-poppins-font-style">
                    Comments
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    rows={5}
                    value={comments}
                    onChange={handleCommentsChange}
                    className="select-invoice-bg-black-textarea"
                  ></textarea>
                </div>
              </div>

              <div className="col-12 pt-4 pb-3 text-end">
                <button
                  className="invoice-approve-btn px-4"
                  onClick={handleUpdate}
                >
                  +{" "}
                  <img src={dashboard_invoice_approve} height={"14px"} alt="" />
                  &nbsp;Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate("/merchant/approvedorders");
          setShowSuccessModal(false);
        }}
        message={"Updated Succesfully"}
      />
    </div>
  );
};

export default AcceptedorderDetailsEdit;
